import { BalanceContentWrapper } from "Components/Balance/BalanceComponent";
import { BitcoinBalance } from "Components/Balance/BtcBalance";
import { UnstyledLink } from "Components/Routes/UnstyledLink";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { PlusMathIcon } from "Components/Shared/Icons";
import styled from "styled-components";
import { getAppUrl } from "Utils/UrlUtils";

const Button = styled(PrimaryButton)`
  margin-top: ${props => props.theme.spacing(2)};
  max-height: 52px;
  justify-content: flex-start;
  text-transform: none;
`;

export const EmptyBalance: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = _ => {
  const appUrl = getAppUrl();

  return (
    <BalanceContentWrapper>
      <BitcoinBalance amount={0}></BitcoinBalance>

      <UnstyledLink to={appUrl("faq")}>
        <Button fullWidth color="info" startIcon={<PlusMathIcon width={24} />}>
          Udělejte první nákup
        </Button>
      </UnstyledLink>
    </BalanceContentWrapper>
  );
};
