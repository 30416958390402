import styled from "styled-components";

import { Typography } from "@mui/material";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { useNavigate } from "react-router";
import { StyledHtmlLink } from "Components/Routes/UnstyledLink";

type Props = {
  title: string;
  text: string;
  icon: JSX.Element;
  url: string;
  isExternalLink: boolean;
};

const Wrapper = styled.div`
  background: radial-gradient(
      circle at 51.748046875% 0%,
      rgba(101, 238, 156, 0.15) 0%,
      15%,
      rgba(101, 238, 156, 0) 50%
    ),
    radial-gradient(
      circle at 13.082682291666666% 84.07877604166667%,
      rgba(255, 255, 255, 0.05) 0%,
      10.5%,
      rgba(255, 255, 255, 0) 35%
    ),
    radial-gradient(
      circle at 79.77376302083333% 63.492838541666664%,
      rgba(56, 184, 240, 0.4) 0%,
      22.5%,
      rgba(56, 184, 240, 0) 75%
    ),
    radial-gradient(
      circle at 10.4296875% 36.66015625%,
      #241932 0%,
      72%,
      rgba(36, 25, 50, 0) 100%
    ),
    radial-gradient(
      circle at 93.6865234375% 11.42578125%,
      #241932 0%,
      42%,
      rgba(36, 25, 50, 0) 70%
    ),
    radial-gradient(
      circle at 48.9013671875% 49.521484375%,
      #cacace 0%,
      100%,
      rgba(202, 202, 206, 0) 100%
    );
  padding: 10px;
  border-radius: 10px;
  min-width: 100px;
  cursor: pointer;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-end;

  svg {
    width: 50px;
    height: 50px;
  }
`;

const Title = styled(Typography)`
  font-size: 18px;
  font-weight: 700;
  color: white;
  display: flex;
  align-self: flex-end;
`;

export const FeaturedItem: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = props => {
  const { title, text, icon, url, isExternalLink } = props;

  const navigate = useNavigate();

  const InnerContent = () => (
    <>
      <StyledFlex justifyContent="space-between">
        <Title>{title}</Title>
        <IconWrapper>{icon}</IconWrapper>
      </StyledFlex>
      <Typography fontWeight={400} fontSize={14} color={"white"}>
        {text}
      </Typography>
    </>
  );

  return (
    <>
      {isExternalLink ? (
        <StyledHtmlLink href={url} target="_blank" rel="noreferrer">
          <Wrapper>
            <InnerContent />
          </Wrapper>
        </StyledHtmlLink>
      ) : (
        <Wrapper onClick={() => navigate(url)}>
          <InnerContent />
        </Wrapper>
      )}
    </>
  );
};
