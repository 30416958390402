import { Grid, Typography } from "@mui/material";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStateType } from "State/Store";
import { TransactionItem } from "Components/Transactions/TransactionItem";
import { getTransactionListAsync } from "State/Transactions/List/GetTransactionListState";
import { UnstyledLink } from "Components/Routes/UnstyledLink";
import { getAppUrl } from "Utils/UrlUtils";
import styled from "styled-components";
import { take } from "lodash-es";
import { downloadTransactionCsvAsync } from "State/Transactions/DownloadCsv/DownloadTransactionCsvState";

const ButtonWrapper = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
`;

type Props = {
  limit: number;
  isShowMoreShown: boolean;
  isExportShown: boolean;
};

const ClickableText = styled(Typography)`
  color: ${props => props.theme.palette.primary.main};
  cursor: pointer;
  font-weight: 600;
`;

export const TransactionListTable: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = props => {
  const { limit, isShowMoreShown, isExportShown } = props;

  const appUrl = getAppUrl();
  const dispatch = useDispatch();
  const { transactionEntityList, transactionItems, isTransactionListLoading } =
    useSelector((e: RootStateType) => e.transaction);
  const [itemLimit, setItemLimit] = React.useState(limit);

  const [offset, setOffset] = React.useState(
    transactionEntityList?.offset ?? 0,
  );
  React.useEffect(() => {
    dispatch(
      getTransactionListAsync.request({
        offset: offset,
        limit: itemLimit,
        isShowMoreShown,
      }),
    );
  }, [dispatch, isShowMoreShown, itemLimit, offset]);

  const [items, setItems] = React.useState(transactionItems);

  React.useEffect(() => {
    setItems(transactionItems);
  }, [transactionItems]);

  if (!isTransactionListLoading && transactionItems.length === 0) {
    if (!isShowMoreShown) {
      return null;
    }

    return (
      <Typography marginTop={theme => theme.spacing(2)}>
        {"Zatím nebyla provedena žádná transakce."}
      </Typography>
    );
  }

  return (
    <>
      <Grid
        container
        display="flex"
        justifyContent={"space-between"}
        alignItems={"stretch"}
      >
        <Grid item>
          <Typography fontWeight={600}>Transakce</Typography>
        </Grid>
        {isExportShown && (
          <Grid>
            <ClickableText
              onClick={() => dispatch(downloadTransactionCsvAsync.request())}
            >
              Exportovat
            </ClickableText>
          </Grid>
        )}
      </Grid>
      {take(items, itemLimit)?.map(e => (
        <TransactionItem key={e.externalID} item={e}></TransactionItem>
      ))}
      <ButtonWrapper>
        {isShowMoreShown &&
          itemLimit < (transactionEntityList?.totalCount ?? 0) && (
            <ClickableText
              onClick={() => {
                setOffset(offset + limit);
                setItemLimit(1000);
              }}
            >
              Načíst další
            </ClickableText>
          )}
      </ButtonWrapper>

      {!isShowMoreShown && (
        <UnstyledLink to={appUrl("transactions")}>{"Historie"}</UnstyledLink>
      )}
    </>
  );
};
