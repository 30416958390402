import { Paper, Typography } from "@mui/material";
import { AuthButtons } from "Components/Auth/Shared/AuthButtons";
import { AuthRedirectProgress } from "Components/Auth/Shared/AuthRedirectProgress";
import {
  AuthPage,
  AuthPageContent,
  AuthHeader,
  AuthDivider,
  AuthFooterSection,
} from "Components/Auth/Shared/AuthStyledComponents";
import { UnauthenticatedHeader } from "Components/Layout/UnauthenticatedHeader";
import { UnauthenticatedLayout } from "Components/Layout/UnauthenticatedLayout";
import { UnstyledLink } from "Components/Routes/UnstyledLink";
import { useSelector } from "react-redux";
import { RootStateType } from "State/Store";
import styled from "styled-components";
import { getAppUrl } from "Utils/UrlUtils";

type Props = {
  title: string;
  isLoading: boolean;
  pageType: "sign-up" | "sign-in" | "other";
};

const StyledPaper = styled(Paper)`
  padding: ${props => props.theme.spacing(7, 2)};
  border-radius: 10px;
`;

export const AuthPageLayout: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = props => {
  const { children, isLoading, pageType, title } = props;

  const ssoIsLoading = useSelector((e: RootStateType) => e.auth.ssoIsLoading);
  const appUrl = getAppUrl();

  return (
    <>
      <UnauthenticatedLayout title={title}>
        <AuthPage>
          <AuthPageContent>
            <div>
              <UnauthenticatedHeader></UnauthenticatedHeader>
            </div>
            <StyledPaper>
              <AuthHeader variant="h2">{title}</AuthHeader>

              {ssoIsLoading === true ? (
                <>
                  <AuthRedirectProgress />
                </>
              ) : (
                <>
                  <>{children}</>
                  {pageType !== "other" && (
                    <>
                      <AuthDivider>
                        <Typography paddingX="10px">nebo</Typography>
                      </AuthDivider>

                      <AuthButtons isLoading={isLoading} />
                    </>
                  )}
                </>
              )}
            </StyledPaper>

            <AuthFooterSection
              alignItems={"center"}
              gap={"6px"}
              justifyContent={"center"}
            >
              <Typography>
                {pageType !== "sign-in"
                  ? "Už máte účet?"
                  : "Ještě nemáte svůj účet?"}
              </Typography>

              <UnstyledLink
                to={appUrl(pageType !== "sign-in" ? "sign-in" : "sign-up")}
              >
                {pageType !== "sign-in" ? "Přihláste se" : "Zaregistrujte se"}
              </UnstyledLink>
            </AuthFooterSection>
          </AuthPageContent>
        </AuthPage>
      </UnauthenticatedLayout>
    </>
  );
};
