import {
  SaveSystemSettingCommandResult,
  postSystemSaveSystemSetting,
  SaveSystemSettingCommand,
} from "Api/Api";
import { createAsyncAction } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { call } from "typed-redux-saga";
import { getSystemSettingAsync } from "State/System/SystemSettings/GetSystemSettingState";

export const saveSystemSettingAsync = createAsyncAction(
  "@system/SAVE_SYSTEM_SETTING_REQUEST",
  "@system/SAVE_SYSTEM_SETTING_SUCCESS",
  "@system/SAVE_SYSTEM_SETTING_FAILURE",
)<SaveSystemSettingCommand, SaveSystemSettingCommandResult, Error>();

function* saveSystemSetting(
  action: ReturnType<typeof saveSystemSettingAsync.request>,
): Generator {
  try {
    const response = yield* call(postSystemSaveSystemSetting, action.payload);
    if (response.status === 200) {
      yield put(
        getSystemSettingAsync.request({
          systemSettingType: action.payload.type,
        }),
      );
      yield put(saveSystemSettingAsync.success(response.json));
      yield put(getSystemSettingAsync.request({systemSettingType: action.payload.type}));
    } else {
      yield put(
        saveSystemSettingAsync.failure(
          new Error(`Error in action ${action.type}`, {
            cause: response.status,
          }),
        ),
      );
    }
  } catch (err) {
    yield put(saveSystemSettingAsync.failure(err as Error));
  }
}
export function* setSystemSettingSaga() {
  yield takeLatest(getType(saveSystemSettingAsync.request), saveSystemSetting);
}
