import * as React from "react";
import { RouteProps, useRoutes } from "react-router-dom";
import { AuthenticatedRoute } from "Routes/AuthenticatedRoute";
import { useSelector } from "react-redux";
import { RootStateType } from "State/Store";
import { getAppUrl } from "Utils/UrlUtils";
import { AuthorizedRoute } from "Routes/AuthorizedRoute";
import { AccessRightCodes } from "Models/AccessRightCodes";
import { isAuthenticated } from "Utils/AuthUtils";
import { wrapUseRoutes } from "@sentry/react";
import GoogleCallbackPage from "Pages/Auth/GoogleCallbackPage";

const Error404Page = React.lazy(() => import("Pages/Error/Error404Page"));

const Error403Page = React.lazy(() => import("Pages/Error/Error403Page"));

const DashboardPage = React.lazy(() => import("Pages/DashboardPage"));
const WithdrawalPage = React.lazy(() => import("Pages/WithdrawalPage"));

const SignInPage = React.lazy(() => import("Pages/Auth/SignInPage"));

const BankIDRedirectPage = React.lazy(
  () => import("Pages/Auth/BankIDRedirectPage"),
);

const GoogleRedirectPage = React.lazy(
  () => import("Pages/Auth/GoogleRedirectPage"),
);

const AppleRedirectPage = React.lazy(
  () => import("Pages/Auth/AppleRedirectPage"),
);

const SignUpPage = React.lazy(() => import("Pages/Auth/SignUpPage"));
const ForgottenPasswordPage = React.lazy(
  () => import("Pages/Auth/ForgottenPasswordPage"),
);
const SetPasswordPage = React.lazy(() => import("Pages/Auth/SetPasswordPage"));
const EmailVerificationPage = React.lazy(
  () => import("Pages/Auth/EmailVerificationPage"),
);
const AwaitingEmailVerificationPage = React.lazy(
  () => import("Pages/Auth/AwaitingEmailVerificationPage"),
);
const RateConfirmationPage = React.lazy(
  () => import("Pages/RateConfirmationPage"),
);
const WelcomePage = React.lazy(() => import("Pages/WelcomePage"));

const FaqPage = React.lazy(() => import("Pages/Faq/FaqPage"));

const FaqWagePage = React.lazy(() => import("Pages/Faq/FaqWagePage"));
const FaqInvestmentPage = React.lazy(
  () => import("Pages/Faq/FaqInvestmentPage"),
);
const FaqQuickExchangePage = React.lazy(
  () => import("Pages/Faq/FaqQuickExchangePage"),
);

const TransactionsPage = React.lazy(
  () => import("Pages/Transactions/TransactionsPage"),
);

const TransactionDetailPage = React.lazy(
  () => import("Pages/Transactions/TransactionDetailPage"),
);

const SettingsPage = React.lazy(() => import("Pages/Settings/SettingsPage"));

const NotificationSettingsPage = React.lazy(
  () => import("Pages/Settings/NotificationSettingsPage"),
);

const DeactivateUserPage = React.lazy(
  () => import("Pages/Settings/DeactivateUserPage"),
);

const ContactPage = React.lazy(() => import("Pages/ContactPage"));

const AccountPage = React.lazy(() => import("Pages/AccountPage"));

const AffiliatePage = React.lazy(() => import("Pages/Account/AffiliatePage"));

const LeadInvitationPage = React.lazy(
  () => import("Pages/Account/LeadInvitationPage"),
);

const AccountSettingsPage = React.lazy(
  () => import("Pages/Settings/AccountSettingsPage"),
);
const VerificationPage = React.lazy(() => import("Pages/VerificationPage"));
const SystemReportPage = React.lazy(
  () => import("Pages/System/SystemReportPage"),
);
const ColdWalletPage = React.lazy(() => import("Pages/System/ColdWalletPage"));
const BankAddressListPage = React.lazy(
  () => import("Pages/BankAddresses/BankAccountListPage"),
);
const BankAccountDetailPage = React.lazy(
  () => import("Pages/BankAddresses/BankAccountDetailPage"),
);
const SystemControlPage = React.lazy(
  () => import("Pages/System/SystemControlPage"),
);
const UserListPage = React.lazy(() => import("Components/Users/UserListPage"));
const UserDetailPage = React.lazy(() => import("Pages/UserDetailPage"));
const QuickExchangePage = React.lazy(() => import("Pages/QuickExchangePage"));

const SellPage = React.lazy(() => import("Pages/SellPage"));

const RedeemPage = React.lazy(() => import("Pages/RedeemPage"));

const RedeemPublicPage = React.lazy(() => import("Pages/RedeemPublicPage"));

const CoinbackListPage = React.lazy(
  () => import("Pages/Coinback/CoinbackListPage"),
);

const CoinbackRedirectPage = React.lazy(
  () => import("Pages/Coinback/CoinbackRedirectPage"),
);

const Routes: React.FunctionComponent<
  React.PropsWithChildren<RouteProps>
> = _ => {
  const user = useSelector((e: RootStateType) => e.auth.user);
  const appUrl = getAppUrl(true);

  const isUserAuthenticated = isAuthenticated(user);
  const useSentryRoutes = wrapUseRoutes(useRoutes);

  const element = useSentryRoutes([
    {
      path: "",
      element: isUserAuthenticated ? (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <DashboardPage />
        </AuthenticatedRoute>
      ) : (
        <SignInPage />
      ),
    },
    {
      path: appUrl("sign-in"),
      element: <SignInPage />,
    },
    {
      path: appUrl("bankID-redirect"),
      element: <BankIDRedirectPage />,
    },
    {
      path: appUrl("google-redirect"),
      element: <GoogleRedirectPage />,
    },
    {
      path: appUrl("google-callback"),
      element: <GoogleCallbackPage />,
    },
    {
      path: appUrl("apple-redirect"),
      element: <AppleRedirectPage />,
    },
    {
      path: appUrl("sign-up"),
      element: <SignUpPage />,
    },
    {
      path: appUrl("forgottenPassword"),
      element: <ForgottenPasswordPage />,
    },
    {
      path: appUrl("setPassword"),
      element: <SetPasswordPage />,
    },
    {
      path: appUrl("welcome"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <WelcomePage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("faq"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <FaqPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("faq-wage"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <FaqWagePage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("faq-investment"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <FaqInvestmentPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("faq-quick-exchange"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <FaqQuickExchangePage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: `${appUrl("faq")}/:question`,
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <FaqPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("account"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <AccountPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("affiliate"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <AffiliatePage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("invite-lead"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <LeadInvitationPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("account-settings"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <AccountSettingsPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("verification"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <VerificationPage />
        </AuthenticatedRoute>
      ),
    },

    {
      path: appUrl("transactions"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <TransactionsPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("transaction-detail"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <TransactionDetailPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("settings"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <SettingsPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("notification-settings"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <NotificationSettingsPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("deactivate-user"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <DeactivateUserPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("contact"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <ContactPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("dashboard"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <DashboardPage />
        </AuthenticatedRoute>
      ),
    },

    {
      path: appUrl("dashboard-withdraw"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <WithdrawalPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("email-verification"),
      element: <EmailVerificationPage />,
    },
    {
      path: appUrl("awaiting-email-verification"),
      element: <AwaitingEmailVerificationPage />,
    },
    {
      path: appUrl("rate-confirmation"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <RateConfirmationPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("system"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <AuthorizedRoute
            code={AccessRightCodes.SystemReport}
            userAccessRightCodes={user?.accessRightCodes}
          >
            <SystemReportPage />
          </AuthorizedRoute>
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("cold-wallet"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <AuthorizedRoute
            code={AccessRightCodes.ColdWallet}
            userAccessRightCodes={user?.accessRightCodes}
          >
            <ColdWalletPage />
          </AuthorizedRoute>
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("system-control"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <AuthorizedRoute
            code={AccessRightCodes.SystemControl}
            userAccessRightCodes={user?.accessRightCodes}
          >
            <SystemControlPage />
          </AuthorizedRoute>
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("user-list"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <AuthorizedRoute
            code={AccessRightCodes.Users}
            userAccessRightCodes={user?.accessRightCodes}
          >
            <UserListPage />
          </AuthorizedRoute>
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("user-detail"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <AuthorizedRoute
            code={AccessRightCodes.Users}
            userAccessRightCodes={user?.accessRightCodes}
          >
            <UserDetailPage />
          </AuthorizedRoute>
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("bank-address-list"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <BankAddressListPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("bank-address-detail"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <BankAccountDetailPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("quick-exchange"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <QuickExchangePage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("sell"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <SellPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("redeem"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <RedeemPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("redeem-public"),
      element: <RedeemPublicPage />,
    },
    {
      path: appUrl("coinback"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <CoinbackListPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: appUrl("coinback-redirect"),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isUserAuthenticated}>
          <CoinbackRedirectPage />
        </AuthenticatedRoute>
      ),
    },
    { path: appUrl("403"), element: <Error403Page /> },
    { path: "*", element: <Error404Page /> },
  ]);

  return element;
};

export { Routes };
