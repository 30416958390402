import { Typography } from "@mui/material";
import { Colors } from "Components/Layout/Themes/Colors";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import styled from "styled-components";

const StyledBlackAuthButton = styled(PrimaryButton)`
  background-color: ${Colors.Black};
  font-size: 14px;
  text-transform: none;
  justify-content: flex-start;

  p {
    font-weight: 500;
  }

  &:hover {
    background-color: ${Colors.Black};
  }

  margin: ${props => props.theme.spacing(1, 0)};
`;

type Props = {
  text: string;
  isLoading: boolean;
  onClick: () => void;
};

export const BlackAuthButton: React.FunctionComponent<Props> = props => {
  return (
    <StyledBlackAuthButton
      onClick={props.onClick}
      type="button"
      fullWidth
      variant="contained"
      color="info"
      isLoading={props.isLoading}
    >
      <Typography paddingLeft={5}>{props.text}</Typography>
    </StyledBlackAuthButton>
  );
};
