import { DeactivateUserCommandResult, putUserDeactivate } from "Api/Api";
import { createAsyncAction } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { call } from "typed-redux-saga";
import { resetUser } from "State/Auth/AuthReducer";

export const deactivateUserAsync = createAsyncAction(
  "@user/DEACTIVATE_REQUEST",
  "@user/DEACTIVATE_SUCCESS",
  "@user/DEACTIVATE_FAILURE",
)<void, DeactivateUserCommandResult, Error>();

function* deactivateUser(
  action: ReturnType<typeof deactivateUserAsync.request>,
): Generator {
  try {
    const response = yield* call(putUserDeactivate);
    if (response.status === 200) {
      yield put(deactivateUserAsync.success(response.json));
      yield put(resetUser({}));
    } else {
      yield put(
        deactivateUserAsync.failure(
          new Error(`Error in action ${action.type}`, {
            cause: response.status,
          }),
        ),
      );
    }
  } catch (err) {
    yield put(deactivateUserAsync.failure(err as Error));
  }
}
export function* deactivateUserSaga() {
  yield takeLatest(getType(deactivateUserAsync.request), deactivateUser);
}
