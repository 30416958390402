import { Grid, Typography } from "@mui/material";
import {
  TransactionListItemDto,
  TransactionType,
  TransactionStatus,
} from "Api/Api";
import { ExchangeBitcoinIcon } from "Components/Shared/Icons";
import { CurrencyCode } from "Models/CurrencyCodes";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useResource } from "Translations/Resources";
import { formatCurrency } from "Utils/CurrencyUtils";
import { formatDate } from "Utils/DateUtils";
import { getStatusName, getTypeName } from "Utils/TransactionUtils";
import { getAppUrl } from "Utils/UrlUtils";

type Props = { item: TransactionListItemDto };

const StyledWrapper = styled.div`
  margin: ${props => props.theme.spacing(2.5, 0)};
  padding-bottom: ${props => props.theme.spacing(1.5)};
  border-bottom: 1px solid ${props => props.theme.colors.border};
  cursor: pointer;
`;

export const TransactionItem: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = props => {
  const { t } = useResource();
  const item = props.item;

  const navigate = useNavigate();
  const appUrl = getAppUrl();

  return (
    <>
      <StyledWrapper
        onClick={() =>
          navigate(`${appUrl("transaction-detail")}/${item.externalID}`)
        }
      >
        <Grid container justifyItems={"center"} alignContent="center">
          <Grid
            item
            xs={2}
            sm={1}
            display="flex"
            alignSelf={"center"}
            justifyContent="flex-start"
          >
            <ExchangeBitcoinIcon width={26} />
          </Grid>

          <Grid
            container
            item
            xs={5}
            sm={8}
            display="flex"
            alignSelf="center"
            direction="column"
          >
            <Grid item xs={12}>
              <Typography fontWeight={600}>{getTypeName(t, item)}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography>{formatDate(item.dateChanged)}</Typography>
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={5}
            sm={3}
            display="flex"
            justifySelf={"flex-end"}
            justifyContent="flex-end"
            justifyItems={"flex-end"}
            alignSelf="flex-end"
            alignItems={"flex-end"}
            direction="column"
          >
            {(item.type === TransactionType.Buy ||
              item.type === TransactionType.Sell ||
              item.type === TransactionType.LeadReward ||
              item.type === TransactionType.LeadRewardConsumer ||
              item.type === TransactionType.Voucher) && (
              <>
                <Typography fontWeight={600}>
                  {formatCurrency(
                    item.orderAskAmount ??
                      // for legacy transactions imported from wix
                      item.cleanAmount * item.cleanPrice + item.fee,
                    item.orderAskCurrencyCode ??
                      // for legacy transactions imported from wix
                      CurrencyCode.CZK,
                  )}
                </Typography>
                {item.status === TransactionStatus.PendingTrade && (
                  <Typography>{getStatusName(t, item)}</Typography>
                )}
                {item.status === TransactionStatus.Completed && (
                  <Typography>
                    +
                    {formatCurrency(
                      item.cleanAmount,
                      item.currencyCode,
                      undefined,
                      true,
                    )}
                  </Typography>
                )}
              </>
            )}
            {item.type === TransactionType.Withdrawal && (
              <>
                <Typography fontWeight={600}>
                  {formatCurrency(
                    item.cleanAmount,
                    item.currencyCode,
                    undefined,
                    true,
                  )}
                </Typography>
                <Typography>{getStatusName(t, item)}</Typography>
              </>
            )}
            {item.type === TransactionType.Coinback && (
              <>
                <Typography fontWeight={600}>
                  {formatCurrency(
                    item.payoutCommissionValue,
                    item.payoutCommissionCurrencyCode,
                    undefined,
                    true,
                  )}
                </Typography>
                {item.status === TransactionStatus.PendingPayout && (
                  <Typography>{getStatusName(t, item)}</Typography>
                )}
                {item.status === TransactionStatus.Completed && (
                  <Typography>
                    +
                    {formatCurrency(
                      item.cleanAmount,
                      item.currencyCode,
                      undefined,
                      true,
                    )}
                  </Typography>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </StyledWrapper>
    </>
  );
};
