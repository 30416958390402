import {
  TransactionDetailDto,
  TransactionStatus,
  TransactionType,
  VoucherType,
} from "Api/Api";
import { Resources } from "Translations/Resources";

type ResourceType = (resourcePath: string, options?: any) => string;

export function getTypeName(t: ResourceType, item: TransactionDetailDto) {
  switch (item.type) {
    case TransactionType.Sell:
      return t(Resources.Transactions.Type.Sell);
    case TransactionType.Withdrawal:
      return t(Resources.Transactions.Type.Withdrawal);
    case TransactionType.LeadReward:
      return t(Resources.Transactions.Type.LeadReward);
    case TransactionType.LeadRewardConsumer:
      return t(Resources.Transactions.Type.LeadRewardConsumer);
    case TransactionType.Voucher:
      return item.voucherType === VoucherType.SignUpSubsidy
        ? t(Resources.Transactions.Type.Voucher.SignUpSubsidy)
        : t(Resources.Transactions.Type.Voucher.GiftCard);
    case TransactionType.Coinback:
      return t(Resources.Transactions.Type.Coinback);
    default:
    case TransactionType.Buy:
      return t(Resources.Transactions.Type.Buy);
  }
}

export const getStatusName = (t: ResourceType, item: TransactionDetailDto) => {
  switch (item.status) {
    case TransactionStatus.Completed:
      return t(Resources.Transactions.Status.Completed);
    case TransactionStatus.WithdrawRequested:
      return t(Resources.Transactions.Status.WithdrawRequested);
    case TransactionStatus.Withdrawn:
      return t(Resources.Transactions.Status.Withdrawn);
    case TransactionStatus.PendingPayout:
      return t(Resources.Transactions.Status.PendingPayout);
    default:
    case TransactionStatus.PendingTrade:
      return t(Resources.Transactions.Status.PendingTrade);
  }
};
