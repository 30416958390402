import { useMediaQuery, useTheme } from "@mui/material";
import { Copyright } from "Components/Layout/CopyRight";
import { PageTitle } from "Components/Shared/PageTitles/PageTitle";
import * as React from "react";
import styled from "styled-components";
import { Resources, useResource } from "Translations/Resources";
import { Menu } from "./Menu/Menu";

const StyledLayoutWrapper = styled.div`
  display: flex;
`;

const StyledWrapper = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  padding: ${props => props.theme.spacing(5, 0, 0, 12)};
  background-color: ${props => props.theme.colors.gray};

  ${props => props.theme.breakpoints.down("md")} {
    padding: ${props => props.theme.spacing(4, 0)};
    padding-bottom: 0;
  }

  ${props => props.theme.breakpoints.down("lg")} {
    padding: ${props => props.theme.spacing(6, 2)};
  }
`;

const StyledContent = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
`;

const StyledChildrenWrapper = styled.div`
  margin-top: ${props => props.theme.spacing(5)};
`;

const StyledCopyright = styled.div`
  margin-left: -${props => props.theme.spacing(12)};

  ${props => props.theme.breakpoints.down("md")} {
    margin-left: 0;
  }
`;

type Props = {
  title: string;
  goBackUrl?: string;
  onChevronClick?: () => void;
};

export const AuthenticatedLayout: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = props => {
  const { t } = useResource();
  const { title, goBackUrl, onChevronClick } = props;

  React.useEffect(() => {
    document.title = `${title} | ${t(Resources.Common.AppName)}`;
  }, [title, t]);

  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <StyledLayoutWrapper>
      <Menu
        title={title}
        isVisible={true}
        goBackUrl={goBackUrl}
        onChevronClick={onChevronClick}
      />
      <br />
      <StyledWrapper>
        {match && (
          <PageTitle
            title={title}
            goBackUrl={goBackUrl}
            onChevronClick={onChevronClick}
          />
        )}

        <StyledContent>
          <StyledChildrenWrapper>{props.children}</StyledChildrenWrapper>
          <StyledCopyright>
            <Copyright />
          </StyledCopyright>
        </StyledContent>
      </StyledWrapper>
    </StyledLayoutWrapper>
  );
};
