import { Typography } from "@mui/material";
import styled from "styled-components";
import { getCurrencySymbol } from "Utils/CurrencyUtils";

type Props = { balance: number; currency: string };

const Wrapper = styled.div`
  display: flex;
  gap: 5px;
  margin: ${props => props.theme.spacing(4, 0, 1, 0)};
`;

const Currency = styled(Typography)`
  display: flex;
  flex-direction: column;
  justify-content: end;
  margin-top: 1px;
`;

export const FiatBalance: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = props => {
  const { balance, currency } = props;
  return (
    <>
      <Wrapper>
        <Typography variant="h4" fontWeight={600} fontSize={26}>
          {new Intl.NumberFormat("cs-CZ", {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          }).format(balance ?? 0)}
        </Typography>
        <Currency fontSize={16}>{getCurrencySymbol(currency)}</Currency>
      </Wrapper>
    </>
  );
};
