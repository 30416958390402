import { Skeleton, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBalanceAsync } from "State/Balance/BalanceReducer";
import { EmptyBalance } from "Components/Balance/EmptyBalance";
import { RootStateType } from "State/Store";
import { GetBalanceQueryResult, TransactionListItemDto } from "Api/Api";
import { getInvoiceListAsync } from "State/Invoices/List/GetInvoiceListState";
import styled from "styled-components";
import { AppUser } from "State/Auth/Models/AuthStateModels";
import { BitcoinBalance } from "Components/Balance/BtcBalance";
import { FiatBalance } from "Components/Balance/FiatBalance";
import { Profits } from "Components/Balance/Profits";
import { BalanceActions } from "Components/Balance/BalanceActions";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";

const StyledCoinWrapper = styled.div`
  display: flex;
  align-items: center;

  p {
    font-weight: 600;
  }

  svg {
    width: 19px;
    height: auto;
  }
`;

const PriceDetailsWrapper = styled.div``;

const InternalContent: React.FunctionComponent<
  React.PropsWithChildren<{
    balance: GetBalanceQueryResult | null;
    transactionList: TransactionListItemDto[];
    isTransactionListLoading: boolean;
    user: AppUser;
  }>
> = ({ balance, transactionList, isTransactionListLoading, user }) => {
  const isLoaded =
    !!balance && (transactionList.length > 0 || !isTransactionListLoading);

  if (
    isLoaded &&
    balance?.availableBalance === 0 &&
    transactionList.length === 0
  ) {
    return <EmptyBalance />;
  }

  return (
    <BalanceContentWrapper>
      <PriceDetailsWrapper>
        <div>
          {isLoaded && <BitcoinBalance amount={balance.availableBalance} />}
          {!isLoaded && <Skeleton height={70} width="60%" />}
        </div>
        <div>
          {isLoaded && (
            <FiatBalance
              balance={balance.fiatBalance}
              currency={balance.fiatCurrencyCode}
            />
          )}
          {!isLoaded && <Skeleton height={40} width="30%" />}
        </div>

        <div>
          {isLoaded && (
            <Profits
              amount={balance.profitFiat}
              currency={balance.fiatCurrencyCode}
              percents={balance.profitPercent}
            />
          )}
          {!isLoaded && <Skeleton height={40} width="30%" />}
        </div>
        {isLoaded && <BalanceActions balance={balance} user={user} />}
        {!isLoaded && (
          <div>
            <Skeleton height={50} width="90%" />
          </div>
        )}
      </PriceDetailsWrapper>
    </BalanceContentWrapper>
  );
};

export const BalanceComponent: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = _ => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getBalanceAsync.request());
    dispatch(getInvoiceListAsync.request());
  }, [dispatch]);

  const { user } = useSelector((e: RootStateType) => e.auth);
  const { balance } = useSelector((e: RootStateType) => e.balance);
  const { transactionItems, isTransactionListLoading } = useSelector(
    (e: RootStateType) => e.transaction,
  );

  if (!user) {
    return null;
  }

  return (
    <>
      <InternalContent
        balance={balance}
        transactionList={transactionItems}
        isTransactionListLoading={isTransactionListLoading}
        user={user}
      />
    </>
  );
};

export const BalanceContentWrapper: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = props => {
  return (
    <PrimaryPaper>
      <StyledCoinWrapper>
        <Typography fontWeight={600} fontSize={16} textTransform="none">
          Vaše portfolio
        </Typography>
      </StyledCoinWrapper>
      {props.children}
    </PrimaryPaper>
  );
};
