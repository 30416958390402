import {
  postAuthSso,
  SignInResult,
  SignInStatus,
  SsoSignInRequest,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { afterSignInSuccess } from "Utils/AuthUtils";
import { NavigateFunction } from "react-router";

export const signInSsoAsync = createAsyncAction(
  "@auth/SIGN_IN_SSO_REQUEST",
  "@auth/SIGN_IN_SSO_SUCCESS",
  "@auth/SIGN_IN_SSO_FAILURE",
)<
  { model: SsoSignInRequest; navigate: NavigateFunction },
  SignInResult,
  Error
>();

function* signInSso(
  action: ReturnType<typeof signInSsoAsync.request>,
): Generator {
  try {
    const response = yield* call(postAuthSso, action.payload.model);
    if (response.status === 200) {
      yield put(signInSsoAsync.success(response.json));
      if (response.json.status === SignInStatus.Success) {
        yield* call(afterSignInSuccess, response.json, action.payload.navigate);
      }
    } else {
      yield put(
        signInSsoAsync.failure(
          new Error(`Error in action ${action.type}`, {
            cause: response.status,
          }),
        ),
      );
    }
  } catch (err) {
    yield put(signInSsoAsync.failure(err as Error));
  }
}
export function* signInSsoSaga() {
  yield takeLatest(getType(signInSsoAsync.request), signInSso);
}
