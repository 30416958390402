import * as React from "react";
export const SafeIcon = (props: React.SVGProps<SVGSVGElement>) => {
  props = {
    ...props,
    color: props.color,
  };
  return (
    <svg
      fill="#1A1A1A"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width="64px"
      height="64px"
      {...props}
    >
      <path
        d="M 4.5898438 1 L 1 4.5898438 L 1 23.410156 L 4 26.410156 L 4 31 L 11 31 L 11 27 L 21 27 L 21 31 L 28 31 L 28 26.410156 L 31 23.410156 L 31 4.5898438 L 27.410156 1 L 4.5898438 1 z M 5.4101562 3 L 26.589844 3 L 29 5.4101562 L 29 7 L 26.589844 7 L 25 8.5898438 L 25 10.410156 L 26.589844 12 L 29 12 L 29 16 L 26.589844 16 L 25 17.589844 L 25 19.410156 L 26.589844 21 L 29 21 L 29 22.589844 L 26.589844 25 L 5.4101562 25 L 3 22.589844 L 3 5.4101562 L 5.4101562 3 z M 14 10 L 14 12 L 16 12 L 16 10 L 14 10 z M 17 10 L 17 12 L 19 12 L 19 10 L 17 10 z M 20 10 L 20 12 L 22 12 L 22 10 L 20 10 z M 6.5898438 11 L 5 12.589844 L 5 15.410156 L 6.5898438 17 L 9.4101562 17 L 11 15.410156 L 11 12.589844 L 9.4101562 11 L 6.5898438 11 z M 14 13 L 14 15 L 16 15 L 16 13 L 14 13 z M 17 13 L 17 15 L 19 15 L 19 13 L 17 13 z M 20 13 L 20 15 L 22 15 L 22 13 L 20 13 z M 14 16 L 14 18 L 16 18 L 16 16 L 14 16 z M 17 16 L 17 18 L 19 18 L 19 16 L 17 16 z M 20 16 L 20 18 L 22 18 L 22 16 L 20 16 z"
        fill="currentColor"
      />
    </svg>
  );
};
