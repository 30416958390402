import * as React from "react";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { EnglishResourcesDictionary } from "Translations/EnglishResourcesDictionary";
import { Routes } from "Routes/Routes";
import { Layout } from "Components/Layout/Layout";
import { Provider } from "react-redux";
import appStore from "State/Store";
import { PersistGate } from "redux-persist/integration/react";
import "react-toastify/dist/ReactToastify.css";
import { configureApiCalls } from "Api/Api";
import { resetUser, extendSignOutDate } from "State/Auth/AuthReducer";
import { BrowserRouter } from "react-router-dom";
import { ModuleLoadingFallback } from "Components/Layout/ModuleLoadingFallback";
import { JWT_KEY } from "Utils/AuthUtils";
import { initializeHotjar } from "Utils/HotjarUtils";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { GoogleOAuthProvider } from "@react-oauth/google";
import * as Sentry from "@sentry/react";
import { trackUserIdentification } from "Utils/TrackingUtils";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: EnglishResourcesDictionary,
    },
    lng: "en",
    fallbackLng: "en",

    interpolation: {
      escapeValue: false,
    },
  });

initializeHotjar();

function App() {
  configureApiCalls({
    jwtKey: JWT_KEY,
    onResponse: r => {
      if (
        r.status !== 200 &&
        r.status !== 201 &&
        r.status !== 401 &&
        r.status !== 503
      ) {
        Sentry.captureEvent({
          message: `Problem while calling API ${JSON.stringify(r)}`,
          level: "log",
        });
      }
      if (r.status === 401) {
        appStore.store.dispatch(resetUser({}));
      } else {
        appStore.store.dispatch(extendSignOutDate());
        trackUserIdentification();
      }
    },
  });

  const clientId = import.meta.env.VITE_GOOGLE_CLIENT_ID;

  return (
    <Provider store={appStore.store}>
      <PersistGate loading={null} persistor={appStore.persistor}>
        <GoogleReCaptchaProvider
          reCaptchaKey="6Lda2ZciAAAAAGjaHbO8SFHLUWxI31AGLbNl3M11"
          useEnterprise={true}
          scriptProps={{
            async: true,
            defer: true,
            appendTo: "body",
            nonce: undefined,
          }}
        >
          <GoogleOAuthProvider clientId={clientId}>
            <BrowserRouter>
              <Layout>
                <React.Suspense fallback={<ModuleLoadingFallback />}>
                  <Routes />
                </React.Suspense>
              </Layout>
            </BrowserRouter>
          </GoogleOAuthProvider>
        </GoogleReCaptchaProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
