import { postVerificationSetBankIdentificationInProgress } from "Api/Api";
import { createAsyncAction } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { call } from "typed-redux-saga";
import { getUserVerificationDetailAsync } from "State/Verification/GetUserVerificationDetail/GetUserVerificationDetailState";

export const setBankAccountVerificationInProgressAsync = createAsyncAction(
  "@verification/SET_BANK_ACCOUNT_IN_PROGRESS_REQUEST",
  "@verification/SET_BANK_ACCOUNT_IN_PROGRESS_SUCCESS",
  "@verification/SET_BANK_ACCOUNT_IN_PROGRESS_FAILURE",
)<void, void, Error>();

function* setBankAccountVerificationInProgress(
  action: ReturnType<typeof setBankAccountVerificationInProgressAsync.request>,
): Generator {
  try {
    const response = yield* call(
      postVerificationSetBankIdentificationInProgress,
    );
    if (response.status === 200) {
      yield put(getUserVerificationDetailAsync.request());
      yield put(setBankAccountVerificationInProgressAsync.success());
    } else {
      yield put(
        setBankAccountVerificationInProgressAsync.failure(
          new Error(`Error in action ${action.type}`, {
            cause: response.status,
          }),
        ),
      );
    }
  } catch (err) {
    yield put(setBankAccountVerificationInProgressAsync.failure(err as Error));
  }
}
export function* setBankAccountVerificationInProgressSaga() {
  yield takeLatest(
    getType(setBankAccountVerificationInProgressAsync.request),
    setBankAccountVerificationInProgress,
  );
}
