import { Typography } from "@mui/material";
import { AppleButton } from "Components/Auth/Apple/AppleButton";
import { AuthBankIDButton } from "Components/Auth/BankID/AuthBankIDButton";
import { GoogleButton } from "Components/Auth/Google/GoogleButton";
import { AuthWrapper } from "Components/Auth/Shared/AuthStyledComponents";

type Props = {
  isLoading: boolean;
};

export const AuthButtons: React.FunctionComponent<Props> = props => {
  const { isLoading } = props;
  return (
    <>
      <AuthWrapper>
        <Typography>{"Přihlásit pomocí:"}</Typography>
        <GoogleButton isLoading={isLoading} />
        <AppleButton isLoading={isLoading} />
        <AuthBankIDButton isLoading={isLoading} />
      </AuthWrapper>
    </>
  );
};
