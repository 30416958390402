import { createReducer, ActionType, createAction } from "typesafe-actions";
import {
  SignInResult,
  SignInStatus,
  EmailVerificationCommandResultStatus,
  SetPasswordCommandStatus,
} from "Api/Api";
import { addMinutes, fromUnixTime } from "date-fns";
import { produce } from "immer";
import { signInAsync } from "State/Auth/SignIn/SignInState";
import { signInSsoAsync } from "State/Auth/SignIn/SignInSsoState";
import { signUpAsync } from "State/Auth/SignUp/SignUpState";
import {
  AppUser,
  ResetPasswordStep,
  SetPasswordStep,
  SignInStep,
} from "State/Auth/Models/AuthStateModels";
import {
  setPasswordAsync,
  setPasswordSetStep,
} from "State/Auth/Passwords/SetPasswordsState";
import { verifyPhoneAsync } from "State/Auth/Verifications/VerifyPhoneState";
import { sendPhoneVerificationTokenAsync } from "State/Auth/Verifications/SendPhoneVerificationTokenState";
import { verifyEmailAsync } from "State/Auth/Verifications/EmailVerificationState";
import {
  resetPasswordAsync,
  setPasswordResetStep,
} from "State/Auth/Passwords/ResetPasswordState";
import { signUpPasswordlessAsync } from "State/Auth/SignUp/SignUpPasswordlessState";
import { getUserInfoAsync } from "State/Auth/UserInfo/GetUserInfoState";

/**
 * Type of global state type, that handles "user"
 */
export type AuthState = {
  user: AppUser | null;
  errorCode: string | null;
  isLoading: boolean;
  ssoIsLoading: boolean;
  signOutDate: string | null;
  signInStep: SignInStep;
  resetPasswordStep: ResetPasswordStep;
  setPasswordStep: SetPasswordStep;
  phoneVerificationToken: string | null;
  unauthenticatedUrl: string | null;
  verificationEmail: string | null;
};

export const resetUser = createAction("@auth/RESET")<{
  authRedirectUrl?: string;
}>();

export const resetError = createAction("@auth/RESET_ERROR")<void>();

export const extendSignOutDate = createAction(
  "@auth/SET_SIGN_OUT_DATE",
)<void>();

export const setSignInStep = createAction(
  "@auth/SET_SIGN_IN_STEP",
)<SignInStep>();

export const closeWelcomePage = createAction(
  "@auth/CLOSE_WELCOME_PAGE",
)<void>();

export const setUnauthenticatedUrl = createAction(
  "@auth/SET_UNAUTHENTICATED_URL",
)<string | null>();

export const setVerificationEmail = createAction(
  "@auth/SET_VERIFICATION_EMAIL",
)<string | null>();

/**
 * Collection of actions, that can change state
 */
export type AuthAction =
  | ActionType<typeof signInAsync>
  | ActionType<typeof signUpAsync>
  | ActionType<typeof signUpPasswordlessAsync>
  | ActionType<typeof signInSsoAsync>
  | ActionType<typeof resetPasswordAsync>
  | ActionType<typeof setPasswordResetStep>
  | ActionType<typeof setPasswordSetStep>
  | ActionType<typeof setPasswordAsync>
  | ActionType<typeof sendPhoneVerificationTokenAsync>
  | ActionType<typeof verifyPhoneAsync>
  | ActionType<typeof verifyEmailAsync>
  | ActionType<typeof setUnauthenticatedUrl>
  | ActionType<typeof resetUser>
  | ActionType<typeof resetError>
  | ActionType<typeof setSignInStep>
  | ActionType<typeof closeWelcomePage>
  | ActionType<typeof extendSignOutDate>
  | ActionType<typeof setVerificationEmail>
  | ActionType<typeof getUserInfoAsync>;

/**
 * State is updated here.
 *
 * The most important rule of using Redux architecture is:
 *
 * NEVER MUTATE STATE !!!!!!!!!!!!!
 *
 * ALWAYS CREATE NEW OBJECT !!!
 */
export const authReducer = createReducer<AuthState, AuthAction>({
  user: null,
  errorCode: null,
  isLoading: false,
  ssoIsLoading: false,
  signOutDate: null,
  signInStep: SignInStep.Credentials,
  resetPasswordStep: ResetPasswordStep.Email,
  setPasswordStep: SetPasswordStep.Password,
  phoneVerificationToken: null,
  unauthenticatedUrl: null,
  verificationEmail: null,
})
  .handleAction(setVerificationEmail, (state, action) => {
    return { ...state, verificationEmail: action.payload };
  })
  .handleAction(resetUser, state => {
    return { ...state, user: null };
  })
  .handleAction(extendSignOutDate, state => {
    const signOutDate = addMinutes(new Date(), 24 * 60).toString();

    return {
      ...state,
      signOutDate,
    };
  })
  .handleAction(verifyPhoneAsync.failure, (state, action) => {
    return {
      ...state,
      errorCode: action.payload.message,
      isLoading: false,
    };
  })
  .handleAction(setUnauthenticatedUrl, (state, action) => {
    return {
      ...state,
      unauthenticatedUrl: action.payload,
    };
  })
  .handleAction(setSignInStep, (state, action) => {
    return {
      ...state,
      signInStep: action.payload,
      isLoading: false,
    };
  })
  .handleAction(closeWelcomePage, state =>
    produce(state, draft => {
      if (!!draft.user) {
        draft.user.isFirstSignIn = false;
      }
      return draft;
    }),
  )
  .handleAction(signInAsync.success, (state, action) => {
    if (action.payload.status === SignInStatus.CodeVerificationStep) {
      return {
        ...state,
        errorCode: null,
        isLoading: false,
        signInStep: SignInStep.VerificationToken,
      };
    }

    if (action.payload.status === SignInStatus.Success) {
      return {
        ...state,
        user: mapSignInResultToAppUser(action.payload),
        errorCode: null,
        isLoading: false,
        signInStep: SignInStep.Credentials,
      };
    }

    return {
      ...state,
      isLoading: false,
      errorCode: action.payload.errorMessage as string,
    };
  })
  .handleAction(signInAsync.request, state => {
    return { ...state, isLoading: true };
  })
  .handleAction(signInAsync.failure, (state, action) => {
    return {
      ...state,
      errorCode: action.payload.message,
      isLoading: false,
    };
  })
  .handleAction(signInSsoAsync.request, state => {
    return { ...state, isLoading: true, ssoIsLoading: true };
  })
  .handleAction(signInSsoAsync.success, (state, action) => {
    if (action.payload.status === SignInStatus.Success) {
      return {
        ...state,
        user: mapSignInResultToAppUser(action.payload),
        errorCode: null,
        isLoading: false,
        ssoIsLoading: false,
        signInStep: SignInStep.Credentials,
      };
    }

    return {
      ...state,
      isLoading: false,
      errorCode: action.payload.errorMessage as string,
    };
  })

  .handleAction(signInSsoAsync.failure, (state, action) => {
    return {
      ...state,
      errorCode: action.payload.message,
      isLoading: false,
      ssoIsLoading: false,
    };
  })
  .handleAction(signUpAsync.success, (state, action) => {
    return {
      ...state,
      isLoading: false,
      verificationEmail: action.payload.login,
    };
  })
  .handleAction(signUpAsync.request, state => {
    return { ...state, isLoading: true };
  })
  .handleAction(signUpAsync.failure, (state, action) => {
    return {
      ...state,
      errorCode: action.payload.message,
      isLoading: false,
    };
  })
  .handleAction(signUpPasswordlessAsync.request, state => {
    return { ...state, isLoading: true };
  })
  .handleAction(signUpPasswordlessAsync.success, (state, action) => {
    return {
      ...state,
      isLoading: false,
    };
  })
  .handleAction(signUpPasswordlessAsync.failure, (state, action) => {
    return {
      ...state,
      errorCode: action.payload.message,
      isLoading: false,
    };
  })
  .handleAction(verifyEmailAsync.success, (state, action) => {
    if (
      action.payload.status === EmailVerificationCommandResultStatus.Success &&
      !!action.payload.signInResult
    ) {
      return {
        ...state,
        user: mapSignInResultToAppUser(action.payload.signInResult),
        errorCode: null,
        isLoading: false,
      };
    }

    return {
      ...state,
      errorCode: "Error",
    };
  })
  .handleAction(resetPasswordAsync.request, state => {
    return {
      ...state,
      isLoading: true,
    };
  })
  .handleAction(resetPasswordAsync.failure, state => {
    return {
      ...state,
      isLoading: false,
    };
  })
  .handleAction(resetPasswordAsync.success, state => {
    return {
      ...state,
      isLoading: false,
      resetPasswordStep: ResetPasswordStep.Success,
    };
  })
  .handleAction(setPasswordResetStep, (state, action) => {
    return {
      ...state,
      resetPasswordStep: action.payload,
    };
  })
  .handleAction(resetError, state => {
    return {
      ...state,
      errorCode: null,
    };
  })
  .handleAction(setPasswordAsync.success, (state, action) => {
    return {
      ...state,
      setPasswordStep:
        action.payload.status === SetPasswordCommandStatus.Success
          ? SetPasswordStep.Success
          : SetPasswordStep.Password,
    };
  })
  .handleAction(setPasswordAsync.failure, (state, action) => {
    return {
      ...state,
      errorCode: action.payload.message,
    };
  })
  .handleAction(setPasswordSetStep, (state, action) => {
    return {
      ...state,
      setPasswordStep: action.payload,
    };
  })
  .handleAction(getUserInfoAsync.success, (state, action) => {
    return {
      ...state,
      user: {
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        accessRightCodes: action.payload.accessRightCodes,
        login: action.payload.login!,
        isFirstSignIn: action.payload.isFirstSignIn,
        tokenExpiration: parseJwt(action.payload.token),
        profilePicture: action.payload.profilePicture ?? null,
        userIdentityProviders: action.payload.userIdentityProviders,
      },
    };
  });

const mapSignInResultToAppUser = (result: SignInResult) => {
  const user: AppUser = {
    login: result.login as string,
    firstName: result.firstName as string,
    lastName: result.lastName as string,
    accessRightCodes: result.accessRightCodes || [],
    isFirstSignIn: result.isFirstSignIn,
    tokenExpiration: parseJwt(result.token as string),
    userIdentityProviders: result.userIdentityProviders,
    profilePicture: result.profilePicture ?? null,
  };
  return user;
};

function parseJwt(token: string) {
  return fromUnixTime(
    JSON.parse(window.atob(token.split(".")[1])).exp,
  ).toISOString();
}
