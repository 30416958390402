import { ResourceDictionary } from "./Resources";

export const EnglishResourcesDictionary: ResourceDictionary = {
  Common: {
    AppName: "Coinwage",
  },
  Errors: {
    ApplicationError: {
      Title: "Nastala chyba",
      Home: "Domů",
    },
    Error404: {
      Subtitle: "Stránka nebyla nalezena",
      Home: "Domů",
    },
    Error403: {
      Subtitle: "K zobrazení této stránky nemáte přístupová práva",
    },
  },
  SignIn: {
    Error: {
      General: "Nastala chyba",
      InvalidLogin: "Špatné heslo nebo e-mail",
      InvalidCredentials: "Špatné heslo nebo e-mail",
      ApplicationVerification: "Špatné heslo nebo e-mail",
      PhoneNumberNotFound: "Telefonní číslo nebylo nalezeno",
      SmsSendingFailed: "Zlyhalo posílání SMS ",
      InvalidToken: "Navalidní token",
      AccountNotFound: "Špatné heslo nebo e-mail",
      AccountIsDeactivated:
        "Účet byl deaktivován. Jestli chcete účet obnovit, kontaktujte podporu.",
    },
  },
  SignUp: {
    Error: {
      General: "Nastala chyba",
      UserExists: "Účet již existuje",
      WeakPassword:
        "Heslo musí mít alespoň 8 znaků, obsahovat jedno malé, jedno velké písmeno a jedno číslo",
    },
  },
  Validation: {
    Required: "Vyplňte pole",
    InvalidEmail: "E-mail je nevalidní",
  },
  Transactions: {
    Type: {
      Buy: "Nákup",
      Sell: "Prodej",
      Withdrawal: "Výběr",
      LeadReward: "Odměna za doporučení",
      LeadRewardConsumer: "Odměna za registraci",
      Voucher: {
        GiftCard: "Dárková karta",
        SignUpSubsidy: "Dárek k registraci",
      },
      Coinback: "Coinback",
    },
    Status: {
      Completed: "Provedeno",
      PendingTrade: "Zpracovává se",
      PendingPayout: "Čeká na potvrzení eshopem",
      WithdrawRequested: "Probíhá výběr",
      Withdrawn: "Provedeno",
    },
  },
  SetPassword: {
    Error: {
      General: "Nastala chyba.",
      TokenNotFound: "Token je navalidní",
      WeakPassword:
        "Heslo musí mít alespoň 8 znaků, obsahovat jedno malé, jedno velké písmeno a jedno číslo",
    },
  },
  BankIDVerification: {
    Error: {
      General:
        "Autentifikace přes BankID se nezdařila. Zkuste to prosím znovu.",
      BankIDUserInfoError: "Nepodařilo se získat data z BankID.",
      VerificationAlreadyExists: "Uživatel z BankID byl již ověřen.",
    },
  },
  Voucher: {
    ApplyError: {
      General: "Nastala chyba",
      NotFound:
        "Zadaný kód není správný. Zkontrolujte, zda je správně opsaný. Pokud problémy přetrvávají, kontaktujte nás.",
      Applied: "Kupon byl použit",
      UserAccountNotFound: "Uživatel nenalezen",
      UserNotFound: "Uživatel nenalezen",
      UserIsWaitingForEmailVerification: "Uživatel nenalezen",
      InvalidRecaptcha:
        "Recaptcha není validní. Refreshnite aplikaci a zkuste to znovu.",
    },
  },
};
