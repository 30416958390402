import { EntityListOfLeadDto, postLeadList } from "Api/Api";
import { createAsyncAction } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { call } from "typed-redux-saga";

export const getLeadListAsync = createAsyncAction(
  "@afl/GET_LEAD_LIST_REQUEST",
  "@afl/GET_LEAD_LIST_SUCCESS",
  "@afl/GET_LEAD_LIST_FAILURE",
)<void, EntityListOfLeadDto | null, Error>();

function* getLeadList(
  action: ReturnType<typeof getLeadListAsync.request>,
): Generator {
  try {
    const response = yield* call(postLeadList);
    if (response.status === 200) {
      yield put(getLeadListAsync.success(response.json));
    } else {
      yield put(
        getLeadListAsync.failure(
          new Error(`Error in action ${action.type}`, {
            cause: response.status,
          }),
        ),
      );
    }
  } catch (err) {
    yield put(getLeadListAsync.failure(err as Error));
  }
}
export function* getLeadListSaga() {
  yield takeLatest(getType(getLeadListAsync.request), getLeadList);
}
