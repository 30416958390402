import * as React from "react";
export const Bitcoin3DIcon = (props: React.SVGProps<SVGSVGElement>) => {
  props = {
    ...props,
    color: props.color,
  };
  return (
    <svg
      width={50}
      height={50}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M45.8334 25C45.8334 36.5042 36.5042 45.8333 25.0001 45.8333C13.4959 45.8333 4.16675 36.5042 4.16675 25C4.16675 13.4958 13.4959 4.16667 25.0001 4.16667C36.5042 4.16667 45.8334 13.4958 45.8334 25Z"
        fill="url(#paint0_linear_447_941)"
      />
      <path
        opacity={0.05}
        d="M28.1251 10.4167H27.0834C25.9345 10.4167 25.0001 11.351 25.0001 12.5C25.0001 11.351 24.0657 10.4167 22.9167 10.4167H21.8751C20.7261 10.4167 19.7917 11.351 19.7917 12.5V13.5417H18.7501C17.6011 13.5417 16.6667 14.476 16.6667 15.625V34.375C16.6667 35.524 17.6011 36.4583 18.7501 36.4583H19.7917V37.5C19.7917 38.649 20.7261 39.5833 21.8751 39.5833H22.9167C24.0657 39.5833 25.0001 38.649 25.0001 37.5C25.0001 38.649 25.9345 39.5833 27.0834 39.5833H28.1251C29.274 39.5833 30.2084 38.649 30.2084 37.5V36.051C31.3709 35.75 32.3501 35.2802 33.1272 34.651C34.6459 33.4198 35.4167 31.6167 35.4167 29.2927C35.4167 27.8958 35.0355 26.6844 34.2855 25.6917C33.9574 25.2531 33.573 24.8771 33.1355 24.5656C33.4574 24.2719 33.7428 23.9396 33.9907 23.5708C34.624 22.6188 34.9438 21.5167 34.9438 20.2927C34.9438 18.0854 34.149 16.3719 32.5803 15.2021C31.9282 14.7156 31.147 14.3406 30.2084 14.0667V12.5C30.2084 11.351 29.274 10.4167 28.1251 10.4167ZM22.9167 18.75H26.249C27.4761 18.75 27.997 19.0073 28.198 19.1604C28.4678 19.3656 28.6053 19.7729 28.6053 20.3698C28.6053 20.9188 28.6053 21.8375 26.4022 21.875H22.9167V18.75ZM22.9167 27.0833H27.0834C28.4605 27.1031 29.1667 27.9417 29.1667 29.574C29.1667 31.2313 27.0126 31.25 26.9209 31.25H22.9167V27.0833Z"
        fill="black"
      />
      <path
        opacity={0.07}
        d="M28.125 10.9375H27.0833C26.2219 10.9375 25.5208 11.6385 25.5208 12.5V14.0625H24.4792V12.5C24.4792 11.6385 23.7781 10.9375 22.9167 10.9375H21.875C21.0135 10.9375 20.3125 11.6385 20.3125 12.5V14.0625H18.75C17.8885 14.0625 17.1875 14.7635 17.1875 15.625V34.375C17.1875 35.2365 17.8885 35.9375 18.75 35.9375H20.3125V37.5C20.3125 38.3615 21.0135 39.0625 21.875 39.0625H22.9167C23.7781 39.0625 24.4792 38.3615 24.4792 37.5V35.9375H25.5208V37.5C25.5208 38.3615 26.2219 39.0625 27.0833 39.0625H28.125C28.9865 39.0625 29.6875 38.3615 29.6875 37.5V35.6396C30.951 35.3646 31.9958 34.8969 32.799 34.2458C34.1906 33.1177 34.8958 31.451 34.8958 29.2927C34.8958 28.0115 34.551 26.9062 33.8698 26.0052C33.4302 25.4177 32.8781 24.9552 32.224 24.6208C32.7542 24.2615 33.201 23.8135 33.5583 23.2802C34.1333 22.4167 34.424 21.4115 34.424 20.2927C34.424 18.2583 33.699 16.6865 32.2698 15.6188C31.5854 15.1073 30.7375 14.7281 29.6885 14.4635V12.5C29.6875 11.6385 28.9865 10.9375 28.125 10.9375ZM22.3958 18.2292H26.249C27.301 18.2292 28.0635 18.4031 28.5135 18.7458C28.925 19.0594 29.126 19.5906 29.126 20.3698C29.126 21.2917 28.8177 22.3552 26.4115 22.3958H22.3958V18.2292ZM22.3958 26.5625H27.0833C28.7604 26.5865 29.6875 27.6562 29.6875 29.574C29.6875 31.7458 27.0333 31.7708 26.9208 31.7708H22.3958V26.5625Z"
        fill="black"
      />
      <path
        d="M21.8749 11.4583H22.9166C23.4916 11.4583 23.9583 11.925 23.9583 12.5V15.625C23.9583 16.2 23.4916 16.6667 22.9166 16.6667H21.8749C21.2999 16.6667 20.8333 16.2 20.8333 15.625V12.5C20.8333 11.925 21.2999 11.4583 21.8749 11.4583ZM27.0833 11.4583H28.1249C28.6999 11.4583 29.1666 11.925 29.1666 12.5V15.625C29.1666 16.2 28.6999 16.6667 28.1249 16.6667H27.0833C26.5083 16.6667 26.0416 16.2 26.0416 15.625V12.5C26.0416 11.925 26.5083 11.4583 27.0833 11.4583ZM21.8749 33.3333H22.9166C23.4916 33.3333 23.9583 33.8 23.9583 34.375V37.5C23.9583 38.075 23.4916 38.5417 22.9166 38.5417H21.8749C21.2999 38.5417 20.8333 38.075 20.8333 37.5V34.375C20.8333 33.8 21.2999 33.3333 21.8749 33.3333ZM27.0833 33.3333H28.1249C28.6999 33.3333 29.1666 33.8 29.1666 34.375V37.5C29.1666 38.075 28.6999 38.5417 28.1249 38.5417H27.0833C26.5083 38.5417 26.0416 38.075 26.0416 37.5V34.375C26.0416 33.8 26.5083 33.3333 27.0833 33.3333ZM17.7083 34.375V15.625C17.7083 15.05 18.1749 14.5833 18.7499 14.5833H26.2489C28.7593 14.5833 30.6593 15.0677 31.9572 16.0365C33.2551 17.0052 33.902 18.425 33.902 20.2927C33.902 21.3135 33.6416 22.2135 33.1249 22.9906C32.6041 23.7677 31.8801 24.3375 30.952 24.7C32.0103 24.9688 32.8437 25.5052 33.4541 26.3198C34.0655 27.1281 34.3749 28.1167 34.3749 29.2927C34.3749 31.2948 33.7405 32.8125 32.4708 33.8417C31.201 34.8708 29.3947 35.3958 27.0468 35.4167H18.7499C18.1749 35.4167 17.7083 34.95 17.7083 34.375ZM21.8749 22.9167H26.4197C28.5687 22.8802 29.6468 22.0583 29.6468 20.3698C29.6468 19.426 29.3739 18.7458 28.8291 18.3312C28.2843 17.9167 27.4249 17.7083 26.2489 17.7083H21.8749V22.9167ZM21.8749 26.0417V32.2917H26.9208C27.9218 32.2917 30.2083 31.8031 30.2083 29.574C30.2083 27.3448 29.0447 26.0698 27.0833 26.0417H21.8749Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_447_941"
          x1={14.5824}
          y1={6.95521}
          x2={35.4178}
          y2={43.0448}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDA1C" />
          <stop offset={1} stopColor="#FEB705" />
        </linearGradient>
      </defs>
    </svg>
  );
};
