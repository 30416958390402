import { Typography } from "@mui/material";
import { FeaturedItem } from "Components/Dashboard/Featured/FeaturedItem";
import {
  MoneyBagBitcoin3DIcon,
  MoneyBoxBitcoin3DIcon,
} from "Components/Shared/Icons";
import { useLeadReward } from "Hooks/SystemSettings/useLeadReward";
import styled from "styled-components";
import { getAppUrl } from "Utils/UrlUtils";

type Props = {};

const List = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  ${props => props.theme.breakpoints.down("sm")} {
    grid-template-columns: repeat(2, 1fr);
  }
  gap: ${props => props.theme.spacing(1)};
`;

export const FeaturedList: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = props => {
  const appUrl = getAppUrl();
  const reward = useLeadReward();

  const list = [
    {
      title: `${reward} Kč`,
      text: "Za pozvání přítele, pro oba!",
      icon: <MoneyBagBitcoin3DIcon />,
      url: appUrl("affiliate"),
      isExternalLink: false,
    },
    {
      title: "Coinback",
      text: "Získejte bitcoin za nákup",
      icon: <MoneyBoxBitcoin3DIcon />,
      url: appUrl("coinback"),
      isExternalLink: false,
    },
  ];

  return (
    <>
      <br />
      <Typography fontWeight={600} fontSize={14} marginBottom={1}>
        Doporučujeme
      </Typography>
      <List>
        {list.map(item => (
          <FeaturedItem key={item.title} {...item} />
        ))}
      </List>
    </>
  );
};
