import { Tooltip, Typography, Zoom } from "@mui/material";
import { Colors } from "Components/Layout/Themes/Colors";
import { HelpIcon } from "Components/Shared/Icons";
import { StyledFlex } from "Components/Shared/StyledComponents";
import styled from "styled-components";
import { getCurrencySymbol } from "Utils/CurrencyUtils";

type Props = {
  amount: number;
  currency: string;
  percents: number;
};

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const IconWrapper = styled.div`
  svg {
    width: 20px;
    height: 20px;
  }
`;

export const Profits: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = props => {
  const { amount, currency, percents } = props;
  const prefix = percents > 0 ? "+" : "";

  const color = percents >= 0 ? Colors.SecondaryMain : Colors.Red;
  return (
    <>
      <Wrapper>
        <StyledFlex gap="3px">
          <Typography fontWeight={600} fontSize={16} color={color}>
            {`${prefix}${new Intl.NumberFormat("cs-CZ", {
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            }).format(amount ?? 0)}`}
          </Typography>

          <Typography fontWeight={400} fontSize={16}>
            {getCurrencySymbol(currency)}
          </Typography>
        </StyledFlex>
        <div>
          <StyledFlex gap="3px">
            <Typography fontWeight={600} fontSize={16} color={color}>
              {percents.toFixed(2)}
            </Typography>
            <Typography fontSize={16}>%</Typography>
          </StyledFlex>
        </div>
        <Tooltip
          title={
            <>
              <div>
                <Typography>
                  Zhodnocení portfolia je rozdíl pořizovací hodnoty bitcoinů
                  oproti jejich aktuální hodnotě. V této částce jsou započítány
                  i bitcoiny, které byly případně odeslány na soukromou
                  peněženku. Pokud byly bitcoiny poslány na soukromou peněženku
                  a následně prodány, není tento prodej započítán, jelikož
                  Coinwage nemá o takovém prodeji informace.
                </Typography>
                <br />
                <Typography>
                  Pokud nakoupíte za 1000 Kč a následně hodnota bitcoinu vzroste
                  o 20%, bude zhodnocení +200Kč, pokud budou bitcoiny přesunuty
                  do soukromé peněženky, bude hodnota portfolia 0 Kč, ale
                  zhodnocení stále +200Kč.
                </Typography>
              </div>
            </>
          }
          enterTouchDelay={0}
          placement="bottom"
          TransitionComponent={Zoom}
        >
          <IconWrapper>
            <HelpIcon />
          </IconWrapper>
        </Tooltip>
      </Wrapper>
    </>
  );
};
