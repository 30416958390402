import {
  EntityListOfUserBankAccountListDto,
  postUserBankAccountBankAddressList,
} from "Api/Api";
import { createAsyncAction } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { call } from "typed-redux-saga";

export const getBankAccountListAsync = createAsyncAction(
  "@bank_account/LIST_REQUEST",
  "@bank_account/LIST_SUCCESS",
  "@bank_account/LIST_FAILURE",
)<void, EntityListOfUserBankAccountListDto, Error>();

function* getBankAccountList(
  action: ReturnType<typeof getBankAccountListAsync.request>,
): Generator {
  try {
    const response = yield* call(postUserBankAccountBankAddressList);
    if (response.status === 200) {
      yield put(getBankAccountListAsync.success(response.json));
    } else {
      yield put(
        getBankAccountListAsync.failure(
          new Error(`Error in action ${action.type}`, {
            cause: response.status,
          }),
        ),
      );
    }
  } catch (err) {
    yield put(getBankAccountListAsync.failure(err as Error));
  }
}
export function* getBankAccountListSaga() {
  yield takeLatest(
    getType(getBankAccountListAsync.request),
    getBankAccountList,
  );
}
