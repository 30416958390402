import { Typography } from "@mui/material";
import { Logo } from "Components/Layout/Logo";
import { UnstyledLink } from "Components/Routes/UnstyledLink";
import styled from "styled-components";
import { getAppUrl } from "Utils/UrlUtils";

const StyledWrapper = styled.div`
  display: flex;
  padding-bottom: ${props => props.theme.spacing(4)};
  padding-top: ${props => props.theme.spacing(6)};
`;

const CompanyName = styled(Typography)`
  font-size: 30px;
  align-self: center;
`;

export const UnauthenticatedHeader: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = _ => {
  const appUrl = getAppUrl();

  return (
    <UnstyledLink to={appUrl("sign-in")}>
      <StyledWrapper>
        <Logo></Logo>
        <CompanyName variant="h1">{"coinwage"}</CompanyName>
      </StyledWrapper>
    </UnstyledLink>
  );
};
