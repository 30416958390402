import { debounce } from "lodash-es";
import { WebLogRequest } from "Api/Api";
import * as Sentry from "@sentry/react";

type WindowWithErrorStack = {
  applicationErrorStack: WebLogRequest[];
  applicationStore: any;
};

const logStorage = window as unknown as WindowWithErrorStack;

const logToApi = () => {
  // console.log(logStorage.applicationErrorStack);
  // try {
  //   postLogError({
  //     webLogRequests: logStorage.applicationErrorStack,
  //     dateCreated: new Date().toISOString(),
  //   });
  // } catch (e) {
  //   Sentry.captureException(e, {
  //     extra: {
  //       doNotShowDialog: true,
  //     },
  //   });
  // }
  // logStorage.applicationErrorStack = [];
};

const logToApiDebounced = debounce(logToApi, 1000);

const logError = (
  error?: Error,
  additionalInfo?: any,
  isUserDialogShown = true,
) => {
  console.log("ErrorService logError", error, additionalInfo);

  if (error || additionalInfo) {
    Sentry.captureException(error ?? additionalInfo, {
      extra: {
        doNotShowDialog: !isUserDialogShown,
      },
    });
    const errorLog: WebLogRequest = {
      dateCreated: new Date().toISOString(),
      error: `${
        error?.message
      } || ${error?.message.toString()} || ${error?.stack?.toString()} `,
      additionalInfo: JSON.stringify(additionalInfo || {}),
      reduxState: JSON.stringify(logStorage.applicationStore?.getState() || {}),
    };
    logStorage.applicationErrorStack = logStorage.applicationErrorStack || [];
    logStorage.applicationErrorStack.push(errorLog);
    logToApiDebounced();
  }
};

export { logError };
