import * as React from "react";
import styled from "styled-components";

type Props = {
  isLoading: boolean;
};

const StyledButton = styled.button`
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;

  padding: 15px 16px 15px 42px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);

  font-size: 14px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  color: ${props => props.theme.palette.primary.dark};

  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 16px;

  &:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
  }

  &:active {
    background-color: #eeeeee;
  }

  &:focus {
    outline: none;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25),
      0 0 0 3px #c8dafc;
  }

  &:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  margin: 10px 0;
  text-align: left;
`;

export const GoogleButton: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = props => {
  const scope = import.meta.env.VITE_GOOGLE_SCOPE;
  const redirectUri = import.meta.env.VITE_GOOGLE_REDIRECT_URL;
  const clientId = import.meta.env.VITE_GOOGLE_CLIENT_ID;

  const redirectUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&access_type=offline&scope=${scope}&redirect_uri=${redirectUri}&client_id=${clientId}`;
  console.log("🚀 ~ redirectUrl:", redirectUrl);

  const buttonClicked = () => {
    window.location.href = redirectUrl;
  };
  return (
    <>
      <StyledButton
        disabled={props.isLoading}
        type="button"
        color="secondary"
        onClick={buttonClicked}
      >
        Přihlásit se přes Google
      </StyledButton>
    </>
  );
};
