import { EntityListOfCategoryDto, getCoinbackCategories } from "Api/Api";
import { createAsyncAction } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { call } from "typed-redux-saga";

export const getCategoryListAsync = createAsyncAction(
  "@coinback/GET_CATEGORY_LIST_REQUEST",
  "@coinback/GET_CATEGORY_LIST_SUCCESS",
  "@coinback/GET_CATEGORY_LIST_FAILURE",
)<void, EntityListOfCategoryDto, Error>();

function* getCategoryList(
  action: ReturnType<typeof getCategoryListAsync.request>,
): Generator {
  try {
    const response = yield* call(getCoinbackCategories);
    if (response.status === 200) {
      yield put(getCategoryListAsync.success(response.json));
    } else {
      yield put(
        getCategoryListAsync.failure(
          new Error(`Error in action ${action.type}`, {
            cause: response.status,
          }),
        ),
      );
    }
  } catch (err) {
    yield put(getCategoryListAsync.failure(err as Error));
  }
}
export function* getCategoryListSaga() {
  yield takeLatest(getType(getCategoryListAsync.request), getCategoryList);
}
