import {
  Grid,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Bitcoin3DIcon } from "Components/Shared/Icons";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { StyledFlex } from "Components/Shared/StyledComponents";
import styled from "styled-components";
import * as CurrencyUtils from "Utils/CurrencyUtils";
import { CurrencyRateDto } from "Api/Api";
import { RateHistoryChart } from "Components/Dashboard/RateHistoryChart";
import { Colors } from "Components/Layout/Themes/Colors";

type Props = {
  fiatPrice?: number;
  fiatCurrency: string;
  rateHistory: CurrencyRateDto[];
};

const Currency = styled(Typography)`
  display: flex;
  flex-direction: column;
  justify-content: end;
  margin-bottom: 8px;
  font-size: 16px;
`;

const Percents = styled(Typography)`
  display: flex;
  flex-direction: column;
  justify-content: end;
  margin-bottom: 8px;
  font-size: 16px;
`;

const Wrapper = styled.div`
  display: flex;

  & > div {
    margin-left: ${props => props.theme.spacing(3)};
  }
`;

const StyledIcon = styled(Bitcoin3DIcon)`
  width: 50px;
  height: 50px;
  display: flex;
  align-self: center;
`;

export const BitcoinTicker: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = props => {
  const { fiatPrice, fiatCurrency, rateHistory } = props;

  const percents =
    !fiatPrice || !rateHistory[0]
      ? 0
      : (fiatPrice / rateHistory[0].bid) * 100 - 100;
  const theme = useTheme();

  const isGtSm = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <>
      <PrimaryPaper>
        <Wrapper>
          <StyledIcon />
          <div>
            <Typography fontWeight={600}>Bitcoin</Typography>

            {!!fiatPrice && (
              <Grid container>
                <Grid item container gap="5px" xs={12} sm={4} md={6} lg={6}>
                  <Grid item>
                    <Typography fontSize={32}>
                      {CurrencyUtils.formatNumberOnly(fiatPrice)}
                    </Typography>
                  </Grid>
                  <Grid item display={"flex"}>
                    <Currency>
                      {CurrencyUtils.getCurrencySymbol(fiatCurrency)}
                    </Currency>
                  </Grid>
                  <Grid item display={"flex"}>
                    <StyledFlex gap="2px">
                      <Percents
                        fontWeight={600}
                        color={
                          percents >= 0 ? Colors.SecondaryMain : Colors.Red
                        }
                      >
                        {`${percents.toFixed(2)}`}
                      </Percents>
                      <Percents fontSize={400}>{`%`}</Percents>
                    </StyledFlex>
                  </Grid>
                </Grid>
                {isGtSm && (
                  <Grid item xs={12} sm={8} md={6} lg={6}>
                    <RateHistoryChart
                      rateHistory={rateHistory}
                      fiatCurrency={fiatCurrency}
                    />
                  </Grid>
                )}
              </Grid>
            )}
            {!fiatPrice && (
              <StyledFlex gap="5px">
                <Skeleton variant="text" width={100} height={50} />
                <Skeleton variant="text" width={80} height={50} />
                <Skeleton variant="text" width={200} height={50} />
              </StyledFlex>
            )}
          </div>
        </Wrapper>
      </PrimaryPaper>
    </>
  );
};
