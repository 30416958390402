import { Colors } from "Components/Layout/Themes/Colors";
import * as React from "react";
import styled from "styled-components";
import { Resources, useResource } from "Translations/Resources";

const StyledContent = styled.div`
  width: 100vw;
  display: grid;
  background-color: ${Colors.BackgroundGrey};
`;

type Props = {
  title: string;
};

export const UnauthenticatedLayout: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = props => {
  const { t } = useResource();
  const { title } = props;

  React.useEffect(() => {
    document.title = `${title} | ${t(Resources.Common.AppName)}`;
  }, [title, t]);

  return (
    <StyledContent>
      <div>{props.children}</div>
    </StyledContent>
  );
};
