import { GetCodeListCollectionQueryResult } from "Api/Api";
import { createReducer, ActionType, createAsyncAction } from "typesafe-actions";

type CodeListState = {
  codeLists: GetCodeListCollectionQueryResult | null;
};

export const getCodeListCollectionAsync = createAsyncAction(
  "@codeList/GET_COLLECTION_REQUEST",
  "@codeList/GET_COLLECTION_SUCCESS",
  "@codeList/GET_COLLECTION_FAILURE",
)<void, GetCodeListCollectionQueryResult, Error>();

type CodeListAction = ActionType<typeof getCodeListCollectionAsync>;

export const codeListReducer = createReducer<CodeListState, CodeListAction>({
  codeLists: null,
})
  .handleAction(getCodeListCollectionAsync.request, state => {
    return { ...state };
  })
  .handleAction(getCodeListCollectionAsync.success, (state, action) => {
    return { ...state, codeLists: action.payload };
  })
  .handleAction(getCodeListCollectionAsync.failure, (state, action) => {
    return {
      ...state,
    };
  });
