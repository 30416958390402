import {
  UserBankAccountDetailDto,
  postUserBankAccountGetDetail,
  UserBankAccountDetailRequest,
} from "Api/Api";
import { createAsyncAction } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { call } from "typed-redux-saga";

export const getBankAddressDetailAsync = createAsyncAction(
  "@bank_address/DETAIL_REQUEST",
  "@bank_address/DETAIL_SUCCESS",
  "@bank_address/DETAIL_FAILURE",
)<UserBankAccountDetailRequest, UserBankAccountDetailDto | null, Error>();

function* getBankAddressDetail(
  action: ReturnType<typeof getBankAddressDetailAsync.request>,
): Generator {
  try {
    const response = yield* call(postUserBankAccountGetDetail, action.payload);
    if (response.status === 200) {
      yield put(getBankAddressDetailAsync.success(response.json));
    } else {
      yield put(
        getBankAddressDetailAsync.failure(
          new Error(`Error in action ${action.type}`, {
            cause: response.status,
          }),
        ),
      );
    }
  } catch (err) {
    yield put(getBankAddressDetailAsync.failure(err as Error));
  }
}
export function* getBankAddressDetailSaga() {
  yield takeLatest(
    getType(getBankAddressDetailAsync.request),
    getBankAddressDetail,
  );
}
