import * as React from "react";
import { BlackAuthButton } from "Components/Auth/Shared/BlackAuthButton";

type Props = {
  isLoading: boolean;
};

export const AuthBankIDButton: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = props => {
  const authUrl = import.meta.env.VITE_BANK_ID_URL;

  const buttonClicked = () => {
    if (!!authUrl) {
      window.location.href = `${authUrl}&scope=profile.email%20profile.name%20openid&response_type=token&state=sign-up`;
    }
  };

  return (
    <BlackAuthButton
      isLoading={props.isLoading}
      onClick={buttonClicked}
      text="Přihlásit se přes BankID"
    />
  );
};
