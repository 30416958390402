import { ObjPathProxy, createProxy, getPath } from "ts-object-path";
import i18next, { ResourceLanguage } from "i18next";
import { useTranslation } from "react-i18next";

export interface ResourceDictionary extends ResourceLanguage {
  Common: {
    AppName: string;
  };
  Errors: {
    ApplicationError: {
      Title: string;
      Home: string;
    };
    Error404: {
      Subtitle: string;
      Home: string;
    };
    Error403: {
      Subtitle: string;
    };
  };
  SignIn: {
    Error: {
      General: string;
      InvalidLogin: string;
      InvalidCredentials: string;
      ApplicationVerification: string;
      PhoneNumberNotFound: string;
      SmsSendingFailed: string;
      InvalidToken: string;
      AccountNotFound: string;
      AccountIsDeactivated: string;
    };
  };
  SignUp: {
    Error: {
      General: string;
      UserExists: string;
      WeakPassword: string;
    };
  };
  SetPassword: {
    Error: {
      General: string;
      TokenNotFound: string;
      WeakPassword: string;
    };
  };
  Validation: {
    Required: string;
    InvalidEmail: string;
  };
  Transactions: {
    Type: {
      Buy: string;
      Sell: string;
      Withdrawal: string;
      LeadReward: string;
      LeadRewardConsumer: string;
      Voucher: {
        GiftCard: string;
        SignUpSubsidy: string;
      };
      Coinback: string;
    };
    Status: {
      Completed: string;
      PendingTrade: string;
      PendingPayout: string;
      WithdrawRequested: string;
      Withdrawn: string;
    };
  };
  BankIDVerification: {
    Error: {
      General: string;
      BankIDUserInfoError: string;
      VerificationAlreadyExists: string;
    };
  };
  Voucher: {
    ApplyError: {
      General: string;
      NotFound: string;
      Applied: string;
      UserAccountNotFound: string;
      UserNotFound: string;
      UserIsWaitingForEmailVerification: string;
      InvalidRecaptcha: string;
    };
  };
}

export const Resources = createProxy<ResourceDictionary>();

function getResourcePath(
  proxy: ObjPathProxy<ResourceDictionary, string>,
): string {
  const path = getPath(proxy);

  if (!path || path.length === 0) return "";
  if (path.length === 1) return path[0].toString();
  return `${path[0].toString()}:${path.slice(1).join(".")}`;
}

export function useResource() {
  const { t: i18Translation } = useTranslation();

  return {
    t: (
      resourcePath: ObjPathProxy<ResourceDictionary, string>,
      options?: any,
    ) =>
      i18Translation<string>(
        getResourcePath(resourcePath),
        options,
      ) as unknown as string,
  };
}

export function useServerError(
  parentObject: ObjPathProxy<ResourceDictionary, object>,
  fallbackProperty: ObjPathProxy<ResourceDictionary, string>,
) {
  const { t } = useResource();
  return {
    translateError: (code: string | null | undefined) => {
      if (!code) {
        return null;
      }

      const newCode = `${getResourcePath(parentObject as any)}.${code}`;

      const resource: string = i18next.exists(newCode)
        ? i18next.t(newCode)
        : (t(fallbackProperty) as unknown as string);

      return resource;
    },
  };
}
