import { PageTitle } from "Components/Shared/PageTitles/PageTitle";
import styled from "styled-components";

type Props = { title: string; goBackUrl?: string; onChevronClick?: () => void };

const MobileWrapper = styled.div`
  .chevron-wrapper {
    margin-top: 10px;
    margin-left: 10px;
  }

  h1 {
    font-size: 22px;
  }

  .go-back-url {
    h1 {
      margin-top: 6px;
      margin-left: 0;
    }
  }
`;

export const PageTitleMobile: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ title, goBackUrl, onChevronClick }) => {
  return (
    <MobileWrapper>
      <PageTitle
        title={title}
        goBackUrl={goBackUrl}
        onChevronClick={onChevronClick}
      />
    </MobileWrapper>
  );
};
