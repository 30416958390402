import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootStateType } from "State/Store";
import rocketLaunch from "Assets/Images/Images/rocket-launch.png";
import styled from "styled-components";
import { formatCurrency } from "Utils/CurrencyUtils";
import { setSignUpSubsidy } from "State/Voucher/VoucherReducer";

type Props = {};

const StyledRocketLaunch = styled.img`
  width: 100%;
`;

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    border-radius: 0;
    ${props => props.theme.breakpoints.down("sm")} {
      width: 280px;
    }
    ${props => props.theme.breakpoints.up("sm")} {
      width: 350px;
    }
  }

  .MuiDialogContent-root {
    padding: 0;
  }
`;

const TextWrapper = styled.div`
  padding: ${props => props.theme.spacing(2)};
`;

export const SignUpSubsidyPopup: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = props => {
  const { signUpSubsidy } = useSelector((e: RootStateType) => e.voucher);

  const dispatch = useDispatch();

  if (signUpSubsidy === null) {
    return null;
  }

  const handleClose = () => {
    dispatch(setSignUpSubsidy(null));
  };

  return (
    <>
      <StyledDialog fullWidth={true} open={true} onClose={handleClose}>
        <DialogContent>
          <div>
            <StyledRocketLaunch src={rocketLaunch} alt="Coinwage" />
          </div>

          <TextWrapper>
            <Typography fontSize={"20px"} fontWeight={700}>
              Něco do začátku…
            </Typography>

            <Typography component="div">
              {
                "Aby byl Váš vstup do Coinwage příjemnější, již vám připisujeme prvních "
              }
              <b>
                {formatCurrency(
                  signUpSubsidy.fiatAmount,
                  signUpSubsidy.currencyCode,
                )}
              </b>
              {
                " v bitcoinech na Váš účet. Věříme, že toto bude začátek dlouhé cesty."
              }
            </Typography>
          </TextWrapper>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Pokračovat</Button>
        </DialogActions>
      </StyledDialog>
    </>
  );
};
