import { IdentityProvider } from "Api/Api";
import { BlackAuthButton } from "Components/Auth/Shared/BlackAuthButton";
import React from "react";
import AppleSignin from "react-apple-signin-auth";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signInSsoAsync } from "State/Auth/SignIn/SignInSsoState";

type Props = {
  isLoading: boolean;
};

export const AppleButton: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = props => {
  const redirectUri = import.meta.env.VITE_APPLE_REDIRECT_URL;
  const clientId = import.meta.env.VITE_APPLE_CLIENT_ID;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSuccess = (response: any) => {
    const token = response.authorization.code;
    if (!!token) {
      dispatch(
        signInSsoAsync.request({
          model: {
            token: token,
            identityProvider: IdentityProvider.Apple,
            affiliateCode: localStorage.getItem("affiliateCode"),
          },
          navigate,
        }),
      );
    }
  };

  const nonce = (Math.random() + 1).toString(36).substring(7);
  const className = props.isLoading
    ? "apple-auth-btn apple-auth-btn-disabled"
    : "apple-auth-btn";
  return (
    // <Wrapper disabled={props.isLoading}>
    <AppleSignin
      /** Auth options passed to AppleID.auth.init() */
      authOptions={{
        /** Client ID - eg: 'com.example.com' */
        clientId: clientId,
        /** Requested scopes, seperated by spaces - eg: 'email name' */
        scope: "email name",
        /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
        redirectURI: redirectUri,
        /** State string that is returned with the apple response */
        state: "state",
        /** Nonce */
        nonce: nonce,
        /** Uses popup auth instead of redirection */
        usePopup: true,
      }} // REQUIRED
      /** General props */
      // uiType="dark"
      /** className */
      className={className}
      /** Removes default style tag */
      noDefaultStyle={false}
      /** Allows to change the button's children, eg: for changing the button text */
      buttonExtraChildren="Pokračovat s Apple"
      /** Extra controlling props */
      /** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
      onSuccess={onSuccess} // default = undefined
      /** Called upon signin error */
      onError={(error: any) => console.log(error)} // default = undefined
      /** Skips loading the apple script if true */
      skipScript={false} // default = undefined
      /** Apple image props */
      iconprop={{ style: { display: "10px" } }} // default = undefined
      /** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
      render={(props: any) => (
        <BlackAuthButton
          text="Pokračovat s Apple"
          isLoading={props.isLoading}
          {...props}
        />
      )}
    />
    // </Wrapper>
  );
};
