import { CurrencyCode } from "Models/CurrencyCodes";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addWatchedRate,
  getRateAsync,
  removeWatchedRates,
} from "State/Rates/RateReducer";
import { RootStateType } from "State/Store";

export const useRate = (
  cryptoCurrencyCode: CurrencyCode,
  fiatCurrencyCode: CurrencyCode,
  useCache: boolean = false,
) => {
  const dispatch = useDispatch();
  const { rates } = useSelector((e: RootStateType) => e.rate);

  React.useEffect(() => {
    dispatch(
      addWatchedRate({
        cryptoCurrencyCode,
        fiatCurrencyCode,
        useCache: useCache,
      }),
    );
    dispatch(getRateAsync.request());

    return () => {
      dispatch(removeWatchedRates());
    };
  }, [dispatch, cryptoCurrencyCode, fiatCurrencyCode, useCache]);

  return rates.find(r => r.cryptoCurrencyCode === cryptoCurrencyCode);
};
