import {
  BankIDVerificationRequest,
  postVerificationVerifyBankId,
  ProcessBankIDVerificationCommandResult,
  ProcessBankIDVerificationCommandResultStatus,
} from "Api/Api";
import { createAsyncAction } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { call } from "typed-redux-saga";
import { NavigateFunction } from "react-router-dom";
import {
  BankIDVerificationStatus,
  setBankIDVerificationStatus,
} from "State/Verification/VerificationReducer";
import { getAppUrl } from "Utils/UrlUtils";
import { toast } from "react-toastify";
import { signInAsync } from "State/Auth/SignIn/SignInState";

export const processBankIDVerificationAsync = createAsyncAction(
  "@verification/BANK_ID_REQUEST",
  "@verification/BANK_ID_SUCCESS",
  "@verification/BANK_ID_FAILURE",
)<
  { request: BankIDVerificationRequest; navigate: NavigateFunction },
  ProcessBankIDVerificationCommandResult,
  Error
>();

function* processBankIDVerification(
  action: ReturnType<typeof processBankIDVerificationAsync.request>,
): Generator {
  try {
    const response = yield* call(
      postVerificationVerifyBankId,
      action.payload.request,
    );
    yield put(setBankIDVerificationStatus(BankIDVerificationStatus.None));

    if (response.status === 200) {
      if (
        !!response.json.signInResult &&
        response.json.status ===
          ProcessBankIDVerificationCommandResultStatus.Success
      ) {
        yield put(processBankIDVerificationAsync.success(response.json));
        yield put(signInAsync.success(response.json.signInResult));
        action.payload.navigate(getAppUrl()("verification"));
        toast.success("Ověření se zdařilo.");
      } else {
        yield put(setBankIDVerificationStatus(response.json.status));
      }
    } else {
      yield put(
        processBankIDVerificationAsync.failure(
          new Error(`Error in action ${action.type}`, {
            cause: response.status,
          }),
        ),
      );
      yield put(
        setBankIDVerificationStatus(
          ProcessBankIDVerificationCommandResultStatus.Fail,
        ),
      );
    }
  } catch (err) {
    yield put(processBankIDVerificationAsync.failure(err as Error));
    yield put(
      setBankIDVerificationStatus(
        ProcessBankIDVerificationCommandResultStatus.Fail,
      ),
    );
  }
}
export function* processBankIDVerificationSaga() {
  yield takeLatest(
    getType(processBankIDVerificationAsync.request),
    processBankIDVerification,
  );
}
