import {
  AuthError,
  postAuthSignIn,
  SignInCommand,
  SignInResult,
  SignInStatus,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { setVerificationEmail } from "State/Auth/AuthReducer";
import { NavigateFunction } from "react-router-dom";
import { getAppUrl } from "Utils/UrlUtils";
import { afterSignInSuccess } from "Utils/AuthUtils";

export const signInAsync = createAsyncAction(
  "@auth/SIGN_IN_REQUEST",
  "@auth/SIGN_IN_SUCCESS",
  "@auth/SIGN_IN_FAILURE",
)<{ model: SignInCommand; navigate: NavigateFunction }, SignInResult, Error>();

function* signIn(action: ReturnType<typeof signInAsync.request>): Generator {
  try {
    const response = yield* call(postAuthSignIn, action.payload.model);
    const appUrl = getAppUrl();

    if (response.status === 200) {
      if (response.json.status !== SignInStatus.Failed) {
        yield put(signInAsync.success(response.json));
        if (response.json.status === SignInStatus.Success) {
          yield* call(
            afterSignInSuccess,
            response.json,
            action.payload.navigate,
          );
        }
      } else {
        yield put(
          signInAsync.failure(
            new Error(response.json.error, {
              cause: response.status,
            }),
          ),
        );
        if (
          response.json.error === AuthError.AccountWaitingForEmailConfirmation
        ) {
          yield put(setVerificationEmail(action.payload.model.login));
          yield* call(() =>
            action.payload.navigate(appUrl("awaiting-email-verification")),
          );
        }
      }
    } else {
      yield put(
        signInAsync.failure(
          new Error(response.json.error, {
            cause: response.status,
          }),
        ),
      );
    }
  } catch (err) {
    yield put(signInAsync.failure(err as Error));
  }
}

export function* signInSaga() {
  yield takeLatest(getType(signInAsync.request), signIn);
}
