import { Typography, useMediaQuery } from "@mui/material";
import { CurrencyRateDto } from "Api/Api";
import { format } from "date-fns";
import { Line, LineChart, Tooltip, YAxis } from "recharts";
import styled, { useTheme } from "styled-components";
import { formatCurrency } from "Utils/CurrencyUtils";
import { nameof } from "Utils/ObjectUtils";

type Props = { rateHistory: CurrencyRateDto[]; fiatCurrency: string };

const StyledLabel = styled(Typography)`
  margin: 0px;
`;

const CustomTooltip = ({ active, payload, fiatCurrency }: any) => {
  if (active && payload && payload.length) {
    const dto: CurrencyRateDto = payload[0].payload;

    return (
      <div className="custom-tooltip">
        <StyledLabel fontWeight={800}>
          {formatCurrency(payload[0].value, fiatCurrency)}
        </StyledLabel>
        <StyledLabel fontSize={12}>
          {format(new Date(dto.dateCreated), "dd.MM.yyyy HH:mm")}
        </StyledLabel>
      </div>
    );
  }

  return null;
};

export const RateHistoryChart: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = props => {
  const { rateHistory, fiatCurrency } = props;
  const gradientId = "gradient";

  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isGreaterThan750 = useMediaQuery("(min-width:750px)");

  const size = isSm && isGreaterThan750 ? 420 : isSm ? 320 : 220;

  return (
    <LineChart width={size} height={50} data={rateHistory}>
      <defs>
        <linearGradient id={gradientId} x1="0" y1="0" x2="1" y2="0">
          <stop offset="0%" stopColor="#38B8F0" />
          <stop offset="100%" stopColor="#65EE9C" />
        </linearGradient>
      </defs>
      <YAxis type="number" domain={["dataMin", "dataMax"]} hide={true} />

      <Line
        type="linear"
        dot={false}
        dataKey={nameof<CurrencyRateDto>("bid")}
        stroke={`url(#${gradientId})`}
        strokeWidth={2}
      />

      <Tooltip
        content={
          <CustomTooltip
            fiatCurrency={fiatCurrency}
            active={undefined}
            payload={undefined}
            label={undefined}
          />
        }
        wrapperStyle={{
          outline: "none",
        }}
      />
    </LineChart>
  );
};
