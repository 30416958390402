import { Grid, Paper, styled, Typography } from "@mui/material";

type Props = {};

const StyledPaper = styled(Paper)`
  margin-top: ${props => props.theme.spacing(1)};
  padding: 10px;

  ${props => props.theme.breakpoints.up("sm")} {
    margin-top: ${props => props.theme.spacing(4)};
    padding: 25px;
  }
  border-radius: 10px;
`;

export const PrimaryPaper: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = props => {
  return <StyledPaper>{props.children}</StyledPaper>;
};

export const PaperRow: React.FunctionComponent<{
  left: JSX.Element | string;
  right: JSX.Element | string;
  rightComponent?: string;
}> = props => {
  const { left, right, rightComponent } = props;
  return (
    <>
      <Grid item xs={4}>
        <Typography textAlign={"left"}>{left}</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography
          fontSize={16}
          fontWeight={500}
          textAlign={"right"}
          sx={{ wordBreak: "break-all" }}
          component={(rightComponent as any) ?? "p"}
        >
          {right}
        </Typography>
      </Grid>
    </>
  );
};
