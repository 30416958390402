import * as React from "react";
export const SystemIcon = (props: React.SVGProps<SVGSVGElement>) => {
  props = {
    ...props,
    color: props.color,
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0,0,256,256"
      width="64px"
      height="64px"
      fillRule="nonzero"
      {...props}
    >
      <g
        fill="#000000"
        fillRule="nonzero"
        stroke="none"
        strokeWidth={1}
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeMiterlimit={10}
        strokeDasharray=""
        strokeDashoffset={0}
        fontFamily="none"
        fontWeight="none"
        fontSize="none"
        textAnchor="none"
        style={{
          mixBlendMode: "normal",
        }}
      >
        <g transform="scale(4,4)">
          <circle cx={48} cy={48} r={3.5} />
          <path
            d="M60.16016,48.90039v-1.80078l2.50684,-2.42383c0.49461,-0.4785 0.6001,-1.23222 0.25586,-1.82812l-3,-5.19532c-0.34357,-0.59641 -1.04931,-0.88199 -1.71095,-0.69234l-3.35253,0.959l-1.5586,-0.89942l-0.8457,-3.38379c-0.16714,-0.66751 -0.76697,-1.13571 -1.45508,-1.13579h-6c-0.6881,0.00007 -1.28792,0.46825 -1.45508,1.13574l-0.8457,3.38379l-1.55859,0.89942l-3.35254,-0.959c-0.66162,-0.18905 -1.36701,0.0964 -1.71094,0.69238l-3,5.19532c-0.34424,0.5959 -0.23875,1.34962 0.25586,1.82812l2.50683,2.42383v1.80078l-2.50684,2.42384c-0.49461,0.4785 -0.6001,1.23222 -0.25586,1.82812l3,5.19532c0.34362,0.59634 1.0493,0.88191 1.71094,0.69238l3.35254,-0.959l1.55859,0.89942l0.8457,3.38379c0.16715,0.66749 0.76699,1.13568 1.45509,1.13575h6c0.6881,-0.00007 1.28792,-0.46825 1.45508,-1.13574l0.8457,-3.38379l1.5586,-0.89942l3.35253,0.959c0.66167,0.19011 1.3677,-0.0956 1.71094,-0.69238l3,-5.19532c0.34424,-0.5959 0.23875,-1.34962 -0.25586,-1.82812zM48,54.5c-3.58985,0 -6.5,-2.91015 -6.5,-6.5c0,-3.58985 2.91015,-6.5 6.5,-6.5c3.58985,0 6.5,2.91015 6.5,6.5c-0.00415,3.58813 -2.91187,6.49585 -6.5,6.5z"
            fill="currentColor"
          />
          <path
            d="M59,0.5h-54c-2.48416,0.00271 -4.49729,2.01584 -4.5,4.5v46c0.00271,2.48416 2.01584,4.49729 4.5,4.5h23c0.82843,0 1.5,-0.67157 1.5,-1.5c0,-0.82843 -0.67157,-1.5 -1.5,-1.5h-23c-0.82805,-0.0009 -1.4991,-0.67195 -1.5,-1.5v-39.5h57v20.5c0,0.82843 0.67157,1.5 1.5,1.5c0.82843,0 1.5,-0.67157 1.5,-1.5v-27c-0.00271,-2.48416 -2.01584,-4.49729 -4.5,-4.5zM3.5,8.5v-3.5c0.0009,-0.82805 0.67195,-1.4991 1.5,-1.5h54c0.82805,0.0009 1.4991,0.67195 1.5,1.5v3.5z"
            fill="currentColor"
          />
          <path
            d="M16.93945,38.06055c0.28123,0.28137 0.66274,0.43945 1.06055,0.43945c0.39781,0 0.77932,-0.15808 1.06055,-0.43945l5,-5c0.28137,-0.28123 0.43945,-0.66274 0.43945,-1.06055c0,-0.39781 -0.15808,-0.77932 -0.43945,-1.06055l-5,-5c-0.58573,-0.58573 -1.53537,-0.58573 -2.1211,0c-0.58573,0.58573 -0.58573,1.53537 0,2.1211l3.93946,3.93945l-3.93946,3.93945c-0.28137,0.28123 -0.43945,0.66274 -0.43945,1.06055c0,0.39781 0.15808,0.77932 0.43945,1.06055z"
            fill="currentColor"
          />
          <path
            d="M31,35.5h-5c-0.82843,0 -1.5,0.67157 -1.5,1.5c0,0.82843 0.67157,1.5 1.5,1.5h5c0.82843,0 1.5,-0.67157 1.5,-1.5c0,-0.82843 -0.67157,-1.5 -1.5,-1.5z"
            fill="currentColor"
          />
          <path
            d="M54,16.5h-44c-0.82841,0.00004 -1.49996,0.67159 -1.5,1.5v28c0.00004,0.82841 0.67159,1.49996 1.5,1.5h17c0.82843,0 1.5,-0.67157 1.5,-1.5c0,-0.82843 -0.67157,-1.5 -1.5,-1.5h-15.5v-25h41v9.5c0,0.82843 0.67157,1.5 1.5,1.5c0.82843,0 1.5,-0.67157 1.5,-1.5v-11c-0.00022,-0.82834 -0.67166,-1.49978 -1.5,-1.5z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
};
