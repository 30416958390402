import {
  GetUserLimitsQueryResult,
  postVerificationGetUserLimits,
} from "Api/Api";
import { createAsyncAction } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { call } from "typed-redux-saga";

export const getUserLimitsAsync = createAsyncAction(
  "@verification/GET_USER_LIMITS_REQUEST",
  "@verification/GET_USER_LIMITS_SUCCESS",
  "@verification/GET_USER_LIMITS_FAILURE",
)<void, GetUserLimitsQueryResult, Error>();

function* getUserLimits(
  action: ReturnType<typeof getUserLimitsAsync.request>,
): Generator {
  try {
    const response = yield* call(postVerificationGetUserLimits);
    if (response.status === 200) {
      yield put(getUserLimitsAsync.success(response.json));
    } else {
      yield put(
        getUserLimitsAsync.failure(
          new Error(`Error in action ${action.type}`, {
            cause: response.status,
          }),
        ),
      );
    }
  } catch (err) {
    yield put(getUserLimitsAsync.failure(err as Error));
  }
}
export function* getUserLimitsSaga() {
  yield takeLatest(getType(getUserLimitsAsync.request), getUserLimits);
}
