import styled from "styled-components";

export const StyledWrapper = styled.div`
  display: grid;
  height: 100%;
  grid-template-rows: auto auto 1fr;
  min-height: ${props => props.theme.vh(100)};
`;

export const StyledContainer = styled.div<{ isGray?: boolean }>`
  display: flex;
  justify-content: center;
  ${props => props.isGray && `background-color: ${props.theme.colors.gray};`}

  & > div {
    width: 95%;
  }
`;
