import { GetSystemReportQueryResult, postSystemGetReport } from "Api/Api";
import { createAsyncAction } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { call } from "typed-redux-saga";

export const getSystemReportAsync = createAsyncAction(
  "@system/GET_SYSTEM_REQUEST",
  "@system/GET_SYSTEM_SUCCESS",
  "@system/GET_SYSTEM_FAILURE",
)<void, GetSystemReportQueryResult | null, Error>();

function* getSystemReport(
  action: ReturnType<typeof getSystemReportAsync.request>,
): Generator {
  try {
    const response = yield* call(postSystemGetReport);
    if (response.status === 200) {
      yield put(getSystemReportAsync.success(response.json));
    } else {
      yield put(
        getSystemReportAsync.failure(
          new Error(`Error in action ${action.type}`, {
            cause: response.status,
          }),
        ),
      );
    }
  } catch (err) {
    yield put(getSystemReportAsync.failure(err as Error));
  }
}
export function* getSystemReportSaga() {
  yield takeLatest(getType(getSystemReportAsync.request), getSystemReport);
}
