import { getSystemVersion } from "Utils/VersioningUtils";
import React from "react";
import styled from "styled-components";

const StyledText = styled.div<{ maxWidth?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${props => props.theme.spacing(1, 0)};
  opacity: 0.32;
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : "unset")};

  ${props => props.theme.breakpoints.down("md")} {
    max-width: unset;
  }
`;

export const Copyright: React.FunctionComponent<
  React.PropsWithChildren<{
    maxWidth?: number;
  }>
> = props => {
  return (
    <StyledText maxWidth={props.maxWidth} title={getSystemVersion()}>
      <br />
      {/* <Typography>© Coinwage</Typography> */}
    </StyledText>
  );
};
