import { Grid, Typography } from "@mui/material";
import { GetBalanceQueryResult } from "Api/Api";
import { UnstyledLink } from "Components/Routes/UnstyledLink";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import {
  PlusMathIcon,
  SubtractIcon,
  ForwardArrowIcon,
} from "Components/Shared/Icons";
import { AppUser } from "State/Auth/Models/AuthStateModels";
import { bitcoinToSatoshi } from "Utils/CurrencyUtils";
import { getAppUrl } from "Utils/UrlUtils";
import styled from "styled-components";

type Props = {
  user: AppUser;
  balance: GetBalanceQueryResult | null;
};

const Wrapper = styled(Grid)`
  margin: ${props => props.theme.spacing(2, 0, 1, 0)};

  button {
    max-height: 40px;
    min-width: 150px;
    margin: ${props => props.theme.spacing(0, 1, 0, 0)};
    ${props => props.theme.breakpoints.down("sm")} {
      max-height: 60px;
      display: flex;
      flex-direction: column;
      min-width: unset;
      width: 95%;

      span {
        margin: 0;
      }
    }
  }
`;

export const BalanceActions: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = props => {
  const { balance } = props;

  const appUrl = getAppUrl();

  return (
    <>
      <Wrapper container>
        <Grid item xs={4} sm={4} marginTop={2}>
          <UnstyledLink to={appUrl("faq")}>
            <PrimaryButton
              variant="contained"
              type="button"
              color="info"
              startIcon={<PlusMathIcon width={20} height={20} />}
            >
              <Typography fontWeight={600} fontSize={14}>
                Nakoupit
              </Typography>
            </PrimaryButton>
          </UnstyledLink>
        </Grid>
        {!!balance && bitcoinToSatoshi(balance.availableBalance) > 10000 && (
          <>
            {
              <Grid item xs={4} sm={4} marginTop={2}>
                <UnstyledLink to={appUrl("sell")}>
                  <PrimaryButton
                    variant="outlined"
                    type="button"
                    color="info"
                    startIcon={<SubtractIcon width={20} height={20} />}
                  >
                    <Typography fontWeight={600} fontSize={14}>
                      Prodej
                    </Typography>
                  </PrimaryButton>
                </UnstyledLink>
              </Grid>
            }

            <Grid item xs={4} sm={4} marginTop={2}>
              <UnstyledLink to={appUrl("dashboard-withdraw")}>
                <PrimaryButton
                  variant="outlined"
                  type="button"
                  color="info"
                  startIcon={<ForwardArrowIcon width={20} height={20} />}
                >
                  <Typography fontWeight={600} fontSize={14}>
                    Výběr
                  </Typography>
                </PrimaryButton>
              </UnstyledLink>
            </Grid>
          </>
        )}
      </Wrapper>
    </>
  );
};
