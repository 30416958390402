import { BalanceComponent } from "Components/Balance/BalanceComponent";
import { BitcoinTicker } from "Components/Dashboard/BitcoinTicker";
import { DashboardPopup } from "Components/Dashboard/DashboardPopup";
import { FeaturedList } from "Components/Dashboard/Featured/FeaturedList";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import { TransactionListTable } from "Components/Transactions/TransactionListTable";
import { SignUpSubsidyPopup } from "Components/Voucher/SignUpSubsidyPopup";
import { subDays } from "date-fns";
import { useRate } from "Hooks/useRate";
import { CurrencyCode } from "Models/CurrencyCodes";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRatesHistoryAsync } from "State/Rates/GerRatesHistoryState";
import { RootStateType } from "State/Store";
import { resetTransactionListPagination } from "State/Transactions/TransactionReducer";

export const DASHBOARD_TRANSACTION_LIMIT = 2;

export const DashboardPage: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = _ => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    return () => {
      dispatch(resetTransactionListPagination());
    };
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(
      getRatesHistoryAsync.request({
        askCurrency: CurrencyCode.CZK,
        bidCurrency: CurrencyCode.BTC,
        startDate: subDays(new Date(), 3),
      }),
    );
  }, [dispatch]);

  const { ratesHistory, isRateHistoryLoading } = useSelector(
    (e: RootStateType) => e.rate,
  );

  const btcRate = useRate(CurrencyCode.BTC, CurrencyCode.CZK, true);

  const rateHistory = isRateHistoryLoading ? [] : ratesHistory;

  return (
    <AuthenticatedLayout title="Přehled">
      <StyledPageWrapper>
        <div className="left-content">
          <BalanceComponent />

          <BitcoinTicker
            fiatPrice={btcRate?.askRate}
            fiatCurrency={CurrencyCode.CZK}
            rateHistory={rateHistory}
          />

          <DashboardPopup />

          <FeaturedList />

          <br />
          <TransactionListTable
            isShowMoreShown={false}
            isExportShown={false}
            limit={DASHBOARD_TRANSACTION_LIMIT}
          />

          <SignUpSubsidyPopup />
        </div>
      </StyledPageWrapper>
    </AuthenticatedLayout>
  );
};

export default DashboardPage;
