import {
  LeadInvitationCommandResult,
  InviteLeadRequest,
  postLeadInvite,
} from "Api/Api";
import { createAsyncAction } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { call } from "typed-redux-saga";

export const inviteLeadAsync = createAsyncAction(
  "@afl/INVITE_LEAD_REQUEST",
  "@afl/INVITE_LEAD_SUCCESS",
  "@afl/INVITE_LEAD_FAILURE",
)<InviteLeadRequest, LeadInvitationCommandResult, Error>();

function* inviteLead(
  action: ReturnType<typeof inviteLeadAsync.request>,
): Generator {
  try {
    const response = yield* call(postLeadInvite, action.payload);
    if (response.status === 200) {
      yield put(inviteLeadAsync.success(response.json));
    } else {
      yield put(
        inviteLeadAsync.failure(
          new Error(`Error in action ${action.type}`, {
            cause: response.status,
          }),
        ),
      );
    }
  } catch (err) {
    yield put(inviteLeadAsync.failure(err as Error));
  }
}
export function* inviteLeadSaga() {
  yield takeLatest(getType(inviteLeadAsync.request), inviteLead);
}
