import { UserAdminDetailDto, getUserAdminDetailUserID } from "Api/Api";
import { createAsyncAction } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { call } from "typed-redux-saga";

export const getUserAdminDetailAsync = createAsyncAction(
  "@user/DETAIL_REQUEST",
  "@user/DETAIL_SUCCESS",
  "@user/DETAIL_FAILURE",
)<number, UserAdminDetailDto | null, Error>();

function* getUserAdminDetail(
  action: ReturnType<typeof getUserAdminDetailAsync.request>,
): Generator {
  try {
    const response = yield* call(getUserAdminDetailUserID, action.payload);
    if (response.status === 200) {
      yield put(getUserAdminDetailAsync.success(response.json));
    } else {
      yield put(
        getUserAdminDetailAsync.failure(
          new Error(`Error in action ${action.type}`, {
            cause: response.status,
          }),
        ),
      );
    }
  } catch (err) {
    yield put(getUserAdminDetailAsync.failure(err as Error));
  }
}
export function* getUserAdminDetailSaga() {
  yield takeLatest(
    getType(getUserAdminDetailAsync.request),
    getUserAdminDetail,
  );
}
