import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { StyledContainer } from "Components/Auth/Styles";
import { AuthRedirectProgress } from "Components/Auth/Shared/AuthRedirectProgress";
import { AuthPageLayout } from "Components/Auth/Shared/AuthPageLayout";
import { setToken } from "Utils/LocalStorageUtils";
import { getUserInfoAsync } from "State/Auth/UserInfo/GetUserInfoState";
import { getAppUrl } from "Utils/UrlUtils";
import { RootStateType } from "State/Store";
import { useEffect } from "react";
import { Button, Typography } from "@mui/material";
import { UnstyledLink } from "Components/Routes/UnstyledLink";
import { Resources, useResource } from "Translations/Resources";
import HomeIcon from "@mui/icons-material/Home";

export const GoogleCallbackPage: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = _ => {
  const dispatch = useDispatch();
  const { t } = useResource();

  const location = useLocation();
  const navigate = useNavigate();
  const url = getAppUrl();

  const token = new URLSearchParams(location.search).get("token");
  console.log("🚀 ~ token:", token);
  const error = new URLSearchParams(location.search).get("error");
  console.log("🚀 ~ error:", error);
  const user = useSelector((e: RootStateType) => e.auth.user);

  useEffect(() => {
    if (!token) {
      return;
    }
    setToken({ token: token });
    dispatch(getUserInfoAsync.request());
  }, [token, dispatch]);

  useEffect(() => {
    if (user) {
      navigate(url("dashboard"));
    }
  }, [user, navigate, url]);

  return (
    <AuthPageLayout title={"Přesměrování"} isLoading={false} pageType="other">
      <StyledContainer>
        {!error && <AuthRedirectProgress />}
        {!!error && (
          <div>
            <br />
            <Typography>Chyba při přihlášení přes Google</Typography>
            <br />
            <UnstyledLink to="/">
              <Button
                startIcon={<HomeIcon />}
                size="large"
                variant="contained"
                color="primary"
              >
                {t(Resources.Errors.Error404.Home)}
              </Button>
            </UnstyledLink>
          </div>
        )}
      </StyledContainer>
    </AuthPageLayout>
  );
};

export default GoogleCallbackPage;
