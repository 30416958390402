
/* eslint-disable */
// THIS FILE WAS GENERATED
// ALL CHANGES WILL BE OVERWRITTEN

// ARCHITECTURE START
  export type FetchResponse<T> = {
    json: T;
    status: number;
    args: any;
    error: any;
  };
  
  type Configuration = {
    jwtKey: string | undefined;
    onResponse?: (response: FetchResponse<any>) => void;
  };
  
  let CONFIG: Configuration = {
    jwtKey: undefined,
    onResponse: () => {},
  };
  
  export function configureApiCalls(configuration: Configuration) {
    CONFIG = { ...CONFIG, ...configuration };
  }
  
  async function fetchJson<T>(...args: any): Promise<FetchResponse<T>> {
    const errorResponse = (response: Response, args: any) => {
      const errorResponse = { status: response.status, json: null as any, args, error: response };
      CONFIG.onResponse && CONFIG.onResponse(errorResponse);
      return errorResponse;
    }

    const errorStatus = (status: number, args: any) => {
      const errorResponse = { status: status, json: null as any, args, error: new Error("Network error", {cause: status}) };
      CONFIG.onResponse && CONFIG.onResponse(errorResponse);
      return errorResponse;
    }

    try {
      const res: Response = await (fetch as any)(...args);
      try {
        const json = await res.json();
       const isSuccess = res.status >= 200 && res.status < 300;
        const response = { json: isSuccess ? json : null, status: res.status, args, error: isSuccess ? null : json };
        CONFIG.onResponse && CONFIG.onResponse(response);
        return response;
      }
      catch {
        return errorResponse(res, args)
      }
    } catch {
      return errorStatus(503, args);
    }
  }
  
  const updateHeaders = (headers: Headers) => {
    if (!headers.has("Content-Type")) {
      headers.append("Content-Type", "application/json");
    }
    const token = CONFIG.jwtKey
      ? localStorage.getItem(CONFIG.jwtKey as any)
      : undefined;
    if (!headers.has("Authorization") && token) {
      headers.append("Authorization", token);
    }
  };

function apiPost<TResponse, TRequest>(
  url: string,
  request: TRequest,
  headers: Headers
) {
  var raw = JSON.stringify(request);
  updateHeaders(headers);
  var requestOptions = {
    method: "POST",
    headers,
    body: raw,
    redirect: "follow",
  };

  return fetchJson<TResponse>(url, requestOptions as any);
}

type ParamsObject = {
  [key: string]: any;
};

function apiGet<TResponse>(
  url: string,
  headers: Headers,
  paramsObject: ParamsObject = {}
) {
  updateHeaders(headers);
  const queryString = Object.entries(paramsObject)
    .filter(([_, val]) => val !== undefined && val !== null)
    .map(([key, val]) => `${key}=${val}`)
    .join("&");
  const maybeQueryString = queryString.length > 0 ? `?${queryString}` : "";
  const requestOptions = {
    method: "GET",
    headers,
    redirect: "follow",
  };
  return fetchJson<TResponse>(`${url}${maybeQueryString}`, requestOptions);
}

function apiPut<TResponse, TRequest>(
  url: string,
  request: TRequest,
  headers: Headers
) {
  updateHeaders(headers);

  var raw = JSON.stringify(request);

  var requestOptions = {
    method: "PUT",
    headers,
    body: raw,
    redirect: "follow",
  };

  return fetchJson<TResponse>(url, requestOptions as any);
}

function apiDelete<TResponse>(
  url: string,
  headers: Headers,
  paramsObject: ParamsObject = {}
) {
  updateHeaders(headers);
  const queryString = Object.entries(paramsObject)
    .filter(([_, val]) => val !== undefined && val !== null)
    .map(([key, val]) => `${key}=${val}`)
    .join("&");
  const maybeQueryString = queryString.length > 0 ? `?${queryString}` : "";

  var requestOptions = {
    method: "DELETE",
    headers,
    redirect: "follow",
  };
  return fetchJson<TResponse>(`${url}${maybeQueryString}`, requestOptions);
}

function apiPatch<TResponse, TRequest>(
  url: string,
  request: TRequest,
  headers: Headers
) {
  updateHeaders(headers);

  var raw = JSON.stringify(request);

  var requestOptions = {
    method: "PATCH",
    headers,
    body: raw,
    redirect: "follow",
  };

  return fetchJson<TResponse>(url, requestOptions as any);
}
// ARCHITECTURE END

export const API_ROUTES = { 
	postAuthSignIn: "/api/Auth/sign-in",
	postAuthSignUp: "/api/Auth/sign-up",
	postAuthSignUpPasswordless: "/api/Auth/sign-up-passwordless",
	postAuthVerifyEmail: "/api/Auth/verify-email",
	postAuthResendVerificationEmail: "/api/Auth/resend-verification-email",
	postAuthSso: "/api/Auth/sso",
	getAuthSsoGoogleCallback: "/api/Auth/sso/google-callback",
	getAuthUserInfo: "/api/Auth/user-info",
	postAuthSendPhoneVerificationToken: "/api/Auth/send-phone-verification-token",
	postAuthVerifyPhone: "/api/Auth/verify-phone",
	postAuthResetPassword: "/api/Auth/reset-password",
	postAuthSetPassword: "/api/Auth/set-password",
	getBalance: "/api/Balance",
	postBalanceWithdraw: "/api/Balance/withdraw",
	postBalanceSell: "/api/Balance/sell",
	getCacheRefresh: "/api/Cache/refresh",
	getCodeList: "/api/CodeList",
	postCodeListCodeLists: "/api/CodeList/code-lists",
	getCoinbackOffers: "/api/Coinback/offers",
	getCoinbackCategories: "/api/Coinback/categories",
	getCoinbackOffersForExtension: "/api/Coinback/offers-for-extension",
	getCoinbackOffer: "/api/Coinback/offer",
	getHashSha256: "/api/Hash/sha-256",
	postInvoiceInvoiceList: "/api/Invoice/invoice-list",
	postInvoiceCreateInvoice: "/api/Invoice/create-invoice",
	postInvoiceGetInvoiceRateChange: "/api/Invoice/get-invoice-rate-change",
	postInvoiceProcessInvoiceRateChange: "/api/Invoice/process-invoice-rate-change",
	postInvoiceDownloadEmployeeGuide: "/api/Invoice/download-employee-guide",
	postInvoiceGetInvoiceSettings: "/api/Invoice/get-invoice-settings",
	postInvoiceSaveInvoiceSettings: "/api/Invoice/save-invoice-settings",
	postInvoiceGetUserFeeRate: "/api/Invoice/get-user-fee-rate",
	postLeadList: "/api/Lead/list",
	postLeadInvite: "/api/Lead/invite",
	postLogError: "/api/Log/Error",
	postNotificationGetNotificationSettings: "/api/Notification/get-notification-settings",
	postNotificationSaveNotificationSettings: "/api/Notification/save-notification-settings",
	postRateGetRate: "/api/Rate/get-rate",
	getRateHistory: "/api/Rate/history",
	postSystemGetReport: "/api/System/get-report",
	postSystemWithdraw: "/api/System/withdraw",
	postSystemGenerateAddress: "/api/System/generate-address",
	postSystemGetSystemSetting: "/api/System/get-system-setting",
	postSystemSaveSystemSetting: "/api/System/save-system-setting",
	postTransactionTransactionList: "/api/Transaction/transaction-list",
	getTransactionDetailExternalID: "/api/Transaction/detail",
	getTransactionCsvExport: "/api/Transaction/csv-export",
	postUserBankAccountGetDetail: "/api/UserBankAccount/get-detail",
	postUserBankAccountBankAddressList: "/api/UserBankAccount/bank-address-list",
	postUserBankAccountCreate: "/api/UserBankAccount/create",
	postUserList: "/api/User/list",
	getUserAdminDetailUserID: "/api/User/admin-detail",
	postUserAdminDetail: "/api/User/admin-detail",
	getUserUserIdentificationsZipUserID: "/api/User/user-identifications-zip",
	putUserDeactivate: "/api/User/deactivate",
	postVerificationGetUserVerificationDetail: "/api/Verification/get-user-verification-detail",
	postVerificationGetUserLimits: "/api/Verification/get-user-limits",
	postVerificationVerifyBankId: "/api/Verification/verify-bank-id",
	postVerificationVerifyAdditionalInfo: "/api/Verification/verify-additional-info",
	postVerificationUploadIdentification: "/api/Verification/upload-identification",
	postVerificationSetBankIdentificationInProgress: "/api/Verification/set-bank-identification-in-progress",
	postVoucherValidate: "/api/Voucher/validate",
	postVoucherApply: "/api/Voucher/apply"
}

export type SignInResult = {
	status: SignInStatus;
	token?: string | null;
	login?: string | null;
	firstName?: string | null;
	lastName?: string | null;
	userIdentityProviders: IdentityProvider[];
	error: AuthError;
	errorMessage?: string | null;
	accessRightCodes: string[];
	isFirstSignIn: boolean;
	profilePicture?: string | null;
};

export enum SignInStatus {
	Success = "Success",
	CodeVerificationStep = "CodeVerificationStep",
	Failed = "Failed",
	UnsafeSuccess = "UnsafeSuccess"
}

export enum IdentityProvider {
	Application = "Application",
	Google = "Google",
	BankID = "BankID",
	Apple = "Apple"
}

export enum AuthError {
	BankIDTokenError = "BankIDTokenError",
	BankIDUserInfoError = "BankIDUserInfoError",
	ApplicationVerification = "ApplicationVerification",
	InvalidCredentials = "InvalidCredentials",
	AccountIsDeactivated = "AccountIsDeactivated",
	AccountNotFound = "AccountNotFound",
	PhoneNumberNotFound = "PhoneNumberNotFound",
	SmsSendingFailed = "SmsSendingFailed",
	InvalidToken = "InvalidToken",
	AccountWaitingForEmailConfirmation = "AccountWaitingForEmailConfirmation"
}

export type SignInCommand = {
	login: string;
	password: string;
	token?: string | null;
};

export type SignUpCommandResult = {
	status: SignUpCommandResultStatus;
};

export enum SignUpCommandResultStatus {
	Success = "Success",
	UserExists = "UserExists",
	WeakPassword = "WeakPassword"
}

export type SignUpCommand = {
	login: string;
	password: string;
	affiliateCode?: string | null;
};

export type PasswordlessSignUpCommandResult = {
	status: PasswordlessSignUpCommandResultStatus;
	signInResult?: SignInResult | null;
	subsidyFiatAmount?: number | null;
	subsidyFiatCurrencyCode?: string | null;
};

export enum PasswordlessSignUpCommandResultStatus {
	UserExists = "UserExists",
	UserCreated = "UserCreated"
}

export type PasswordlessSignUpCommand = {
	login: string;
	voucherCode?: string | null;
};

export type EmailVerificationCommandResult = {
	signInResult?: SignInResult | null;
	status: EmailVerificationCommandResultStatus;
};

export enum EmailVerificationCommandResultStatus {
	NotFound = "NotFound",
	Success = "Success"
}

export type EmailVerificationCommand = {
	token: string;
};

export type ResendVerificationEmailCommandResult = {
	status: ResendVerificationEmailCommandResultStatus;
};

export enum ResendVerificationEmailCommandResultStatus {
	Success = "Success"
}

export type ResendVerificationEmailCommand = {
	email: string;
};

export type SsoSignInRequest = {
	token: string;
	affiliateCode?: string | null;
	identityProvider: IdentityProvider;
};

export type UserInfoQueryResult = {
	token: string;
	login: string;
	firstName: string;
	lastName: string;
	profilePicture?: string | null;
	isFirstSignIn: boolean;
	userIdentityProviders: IdentityProvider[];
	accessRightCodes: string[];
};

export type SendPhoneVerificationTokenCommandResult = {
	status: SendPhoneVerificationTokenCommandResultStatus;
};

export enum SendPhoneVerificationTokenCommandResultStatus {
	Success = "Success",
	TokenNotFound = "TokenNotFound",
	SmsSendingFailed = "SmsSendingFailed"
}

export type SendPhoneVerificationTokenCommand = {
	token: string;
	phone: string;
};

export type VerifyPhoneCommandResult = {
	status: VerifyPhoneCommandResultStatus;
	signInResult?: SignInResult | null;
};

export enum VerifyPhoneCommandResultStatus {
	Success = "Success",
	TokenNotFound = "TokenNotFound"
}

export type VerifyPhoneCommand = {
	token: string;
};

export type ResetPasswordCommandResult = {
	status: ResetPasswordCommandResultStatus;
};

export enum ResetPasswordCommandResultStatus {
	Success = "Success",
	Fail = "Fail"
}

export type ResetPasswordCommand = {
	login: string;
};

export type SetPasswordCommandResult = {
	status: SetPasswordCommandStatus;
	signInResult?: SignInResult | null;
};

export enum SetPasswordCommandStatus {
	Success = "Success",
	WeakPassword = "WeakPassword",
	TokenNotFound = "TokenNotFound"
}

export type SetPasswordCommand = {
	token: string;
	password: string;
};

export type GetBalanceQueryResult = {
	availableBalance: number;
	currencyCode: string;
	fiatBalance: number;
	fiatCurrencyCode: string;
	profitPercent: number;
	profitFiat: number;
};

export type AddWithdrawalToQueueCommandResult = {
	status: AddWithdrawalToQueueCommandResultStatus;
};

export enum AddWithdrawalToQueueCommandResultStatus {
	Success = "Success",
	AccountNotFound = "AccountNotFound",
	InsufficientFunds = "InsufficientFunds",
	BlockedFinances = "BlockedFinances"
}

export type WithdrawalRequest = {
	address: string;
	amount: number;
	priority: WithdrawalPriority;
};

export enum WithdrawalPriority {
	Low = "Low",
	High = "High"
}

export type SellCommandResult = {
	status: SellCommandResultStatus;
};

export enum SellCommandResultStatus {
	AccountNotFound = "AccountNotFound",
	InsufficientFunds = "InsufficientFunds",
	Success = "Success",
	RateExpired = "RateExpired",
	BankAccountDoesNotBelongToUser = "BankAccountDoesNotBelongToUser"
}

export type SellRequest = {
	amount: number;
	fiatPrice: number;
	userBankAccountID: number;
};

export type GetCodeListCollectionQueryResult = {
	countries: CodeListItemDto[];
	systemSettings: SystemSettingDto[];
};

export type CodeListItemDto = {
	id: number;
	code: string;
	name: string;
};

export type SystemSettingDto = {
	type: SystemSettingType;
	value: string;
};

export enum SystemSettingType {
	InvoicePaymentProcessorJob = "InvoicePaymentProcessorJob",
	TradeJob = "TradeJob",
	WithdrawalJob = "WithdrawalJob",
	SendinblueEmails = "SendinblueEmails",
	LeadRewardForBoth = "LeadRewardForBoth",
	WithdrawalStaticFeeSat = "WithdrawalStaticFeeSat",
	MaxInvitationCount = "MaxInvitationCount",
	MempoolFeeLevel = "MempoolFeeLevel"
}

export type EntityListOfOfferListItemDto = {
	offset: number;
	limit: number;
	totalCount: number;
	items: OfferListItemDto[];
};

export type OfferListItemDto = {
	name: string;
	webUrl: string;
	commissionType: CommissionType;
	commissionValue: number;
	commissionCurrencyCode?: string | null;
	logoUrl?: string | null;
	rank: number;
	visibility: OfferVisibility;
	bestLink: string;
};

export enum CommissionType {
	Percents = "Percents",
	Fiat = "Fiat"
}

export enum OfferVisibility {
	Visible = "Visible",
	Hidden = "Hidden",
	Promoted = "Promoted"
}

export type EntityListOfCategoryDto = {
	offset: number;
	limit: number;
	totalCount: number;
	items: CategoryDto[];
};

export type CategoryDto = {
	name: string;
	code: string;
	rank: number;
};

export type EntityListOfOfferBrowserExtensionDto = {
	offset: number;
	limit: number;
	totalCount: number;
	items: OfferBrowserExtensionDto[];
};

export type OfferBrowserExtensionDto = {
	webUrl: string;
};

export type OfferDto = {
	name: string;
	webUrl: string;
	commissionType: CommissionType;
	commissionValue: number;
	commissionCurrencyCode?: string | null;
	logoUrl?: string | null;
	rank: number;
	visibility: OfferVisibility;
	bestLink: string;
};

export type EntityListOfInvoiceListItemDto = {
	offset: number;
	limit: number;
	totalCount: number;
	items: InvoiceListItemDto[];
};

export type InvoiceListItemDto = {
	invoiceID: number;
	askAmount: number;
	askCurrencyID: number;
	askCurrencyCode: string;
	status: InvoiceStatus;
	paymentReference: number;
	type: InvoiceType;
	payerReference: string;
	accountNumber: string;
	iban: string;
};

export enum InvoiceStatus {
	New = "New",
	Paid = "Paid",
	Processed = "Processed",
	WaitingForRateConfirmation = "WaitingForRateConfirmation",
	WaitingForRateConfirmationAndPaid = "WaitingForRateConfirmationAndPaid",
	DeclinedBecauseRateConfirmation = "DeclinedBecauseRateConfirmation",
	AcceptedBecauseRateConfirmation = "AcceptedBecauseRateConfirmation"
}

export enum InvoiceType {
	Opened = "Opened",
	FixedPrice = "FixedPrice",
	MarketPrice = "MarketPrice",
	CustomPrice = "CustomPrice"
}

export type CreateInvoiceCommandResult = {
	status: CreateInvoiceCommandResultStatus;
	invoiceID: number;
};

export enum CreateInvoiceCommandResultStatus {
	Success = "Success"
}

export type CreateInvoiceRequest = {
	askCurrencyCode: string;
	askAmount?: number | null;
	bidCurrencyCode: string;
	bidAmount?: number | null;
	rate?: number | null;
	ownershipType: OwnershipType;
	cryptoAddress?: string | null;
	invoiceType: InvoiceType;
};

export enum OwnershipType {
	NonCustodial = "NonCustodial",
	Custodial = "Custodial"
}

export type GetInvoiceRateChangeDetailQueryResult = {
	invoiceRateChangeID: number;
	invoiceID: number;
	invoiceFixedRate: number;
	invoiceAskAmount: number;
	invoiceAskCurrencyCode: string;
	rate: number;
	dateCalculated: string;
	externalID: string;
};

export type GetInvoiceRateChangeDetailQuery = {
	externalID: string;
};

export type ProcessInvoiceRateChangeCommandResult = {
	status: ProcessInvoiceRateChangeCommandResultStatus;
};

export enum ProcessInvoiceRateChangeCommandResultStatus {
	Success = "Success",
	Fail = "Fail",
	NotFound = "NotFound"
}

export type ProcessInvoiceRateChangeRequest = {
	isAccepted: boolean;
	externalID: string;
};

export type InvoiceSettingsDto = {
	ownershipType: OwnershipType;
	cryptoAddress?: string | null;
};

export type SaveInvoiceSettingsCommandResult = {
	status: SaveInvoiceSettingsCommandResultStatus;
};

export enum SaveInvoiceSettingsCommandResultStatus {
	Success = "Success"
}

export type GetUserFeeRateQueryResult = {
	feeRate: number;
	remainingAmount: number;
	remainingAmountCurrencyCode: string;
	nextFeeRate: number;
};

export type EntityListOfLeadDto = {
	offset: number;
	limit: number;
	totalCount: number;
	items: LeadDto[];
};

export type LeadDto = {
	email: string;
	status: LeadStatus;
	code: string;
};

export enum LeadStatus {
	Invited = "Invited",
	Registered = "Registered",
	Completed = "Completed",
	CompletedRewardSent = "CompletedRewardSent",
	CompletedRewardApplied = "CompletedRewardApplied"
}

export type LeadInvitationCommandResult = {
	status: LeadInvitationCommandResultStatus;
};

export enum LeadInvitationCommandResultStatus {
	Success = "Success",
	UserExists = "UserExists",
	LeadExists = "LeadExists"
}

export type InviteLeadRequest = {
	email: string;
};

export type ErrorWebLogCommand = {
	dateCreated: string;
	webLogRequests: WebLogRequest[];
};

export type WebLogRequest = {
	dateCreated: string;
	error: string;
	additionalInfo?: string | null;
	reduxState?: string | null;
};

export type UserNotificationSettingsDto = {
	isFilledOrderNotificationActive: boolean;
	isCreatedOrderNotificationActive: boolean;
	isWithdrawalNotificationActive: boolean;
};

export type SaveUserNotificationSettingsCommandResult = {
	status: SaveUserNotificationSettingsCommandResultStatus;
};

export enum SaveUserNotificationSettingsCommandResultStatus {
	Success = "Success"
}

export type GetRateQueryResult = {
	fiatCurrencyCode: string;
	cryptoCurrencyCode: string;
	askRate: number;
	bidRate: number;
	dateExecuted: string;
};

export type GetRateQuery = {
	fiatCurrencyCode: string;
	cryptoCurrencyCode: string;
	useCache: boolean;
};

export type GetRateHistoryQueryResult = {
	items: EntityListOfCurrencyRateDto;
};

export type EntityListOfCurrencyRateDto = {
	offset: number;
	limit: number;
	totalCount: number;
	items: CurrencyRateDto[];
};

export type CurrencyRateDto = {
	dateCreated: string;
	askCurrencyCode: string;
	bidCurrencyCode: string;
	ask: number;
	bid: number;
};

export type GetSystemReportQueryResult = {
	exchangeWithdrawalFee: number;
	exchangeWithdrawSum: number;
	withdrawalSum: number;
	blockchainFee: number;
	mempoolFeeLevel: string;
	coinmateBalances: ExchangeBalanceDto[];
	userAccountBalance: number;
	coinmateVolume: TradedVolumeDto;
	coinmateMonthlyVolume: TradedVolumeDto;
	coinmateWeeklyVolume: TradedVolumeDto;
	coinmateDailyVolume: TradedVolumeDto;
	coinmateBoughtBtc: number;
	withdrawalTransactionSum: number;
	accountingSumBTC: number;
	accountingSumCZK: number;
	walletBalance: GetBitcoinWalletBalanceQueryResult;
	coldWalletBalance: GetBitcoinWalletBalanceQueryResult;
	sellTransactionSum: number;
};

export type ExchangeBalanceDto = {
	currencyCode: string;
	availableBalance: number;
	reservedBalance: number;
};

export type TradedVolumeDto = {
	currencyCode: string;
	askedVolume: number;
};

export type GetBitcoinWalletBalanceQueryResult = {
	balance: number;
	fiatBalance: number;
};

export type ProcessSystemWithdrawalResult = {
	status: ProcessSystemWithdrawalResultStatus;
};

export enum ProcessSystemWithdrawalResultStatus {
	Success = "Success"
}

export type SystemWithdrawalRequest = {
	amount: number;
	address: string;
};

export type GenerateSystemAddressCommandResult = {
	address: string;
	status: GenerateSystemAddressCommandResultStatus;
};

export enum GenerateSystemAddressCommandResultStatus {
	Success = "Success"
}

export type GetSystemSettingQuery = {
	systemSettingType: SystemSettingType;
};

export type SaveSystemSettingCommandResult = {
	status: SaveSystemSettingCommandResultStatus;
};

export enum SaveSystemSettingCommandResultStatus {
	Success = "Success"
}

export type SaveSystemSettingCommand = {
	type: SystemSettingType;
	value: string;
};

export type EntityListOfTransactionListItemDto = {
	offset: number;
	limit: number;
	totalCount: number;
	items: TransactionListItemDto[];
};

export type TransactionListItemDto = {
	externalID: string;
	cleanPrice: number;
	cleanAmount: number;
	currencyCode?: string | null;
	currencyName: string;
	blockchainTransactionID?: string | null;
	fee: number;
	type: TransactionType;
	dateChanged: string;
	orderFee?: number | null;
	orderPrice?: number | null;
	orderAskAmount?: number | null;
	orderAskCurrencyCode?: string | null;
	orderBidCurrencyCode?: string | null;
	status: TransactionStatus;
	voucherType?: VoucherType | null;
	payoutCommissionValue?: number | null;
	payoutCommissionCurrencyCode?: string | null;
	payoutOfferLinkOfferName?: string | null;
};

export enum TransactionType {
	Buy = "Buy",
	Sell = "Sell",
	Withdrawal = "Withdrawal",
	LeadReward = "LeadReward",
	Voucher = "Voucher",
	Coinback = "Coinback",
	LeadRewardConsumer = "LeadRewardConsumer"
}

export enum TransactionStatus {
	Completed = "Completed",
	PendingTrade = "PendingTrade",
	WithdrawRequested = "WithdrawRequested",
	Withdrawn = "Withdrawn",
	PendingPayout = "PendingPayout"
}

export enum VoucherType {
	GiftCard = "GiftCard",
	SignUpSubsidy = "SignUpSubsidy",
	GiftCardDigital = "GiftCardDigital"
}

export type GetTransactionListRequest = {
	offset: number;
	limit: number;
};

export type GetTransactionDetailQueryResult = {
	detail?: TransactionDetailDto | null;
};

export type TransactionDetailDto = {
	externalID: string;
	cleanPrice: number;
	cleanAmount: number;
	currencyCode?: string | null;
	currencyName: string;
	recipientAddress?: string | null;
	blockchainTransactionID?: string | null;
	fee: number;
	type: TransactionType;
	dateChanged: string;
	orderFee?: number | null;
	orderPrice?: number | null;
	orderAskAmount?: number | null;
	orderAskCurrencyCode?: string | null;
	orderBidCurrencyCode?: string | null;
	status: TransactionStatus;
	voucherType?: VoucherType | null;
	payoutCommissionValue?: number | null;
	payoutCommissionCurrencyCode?: string | null;
	payoutOfferLinkOfferName?: string | null;
};

export type UserBankAccountDetailDto = {
	accountNumberIBANEUR: string;
	externalID: string;
	paymentReference: string;
	accountNumber: string;
	accountNumberIBAN: string;
};

export type UserBankAccountDetailRequest = {
	externalID?: string | null;
};

export type EntityListOfUserBankAccountListDto = {
	offset: number;
	limit: number;
	totalCount: number;
	items: UserBankAccountListDto[];
};

export type UserBankAccountListDto = {
	userBankAccountID: number;
	userID: number;
	iban?: string | null;
	status: UserBankAccountStatus;
	externalID: string;
	formattedAccountNumber?: string | null;
};

export enum UserBankAccountStatus {
	New = "New",
	NewToVerify = "NewToVerify",
	Verified = "Verified",
	VerifiedIBANParsingError = "VerifiedIBANParsingError",
	Rejected = "Rejected"
}

export type CreateUserBankAccountCommandResult = {
	externalID: string;
	status: CreateUserBankAccountCommandResultStatus;
};

export enum CreateUserBankAccountCommandResultStatus {
	Success = "Success"
}

export type EntityListOfUserDto = {
	offset: number;
	limit: number;
	totalCount: number;
	items: UserDto[];
};

export type UserDto = {
	userID: number;
	login: string;
	firstName: string;
	lastName: string;
	dateCreated: string;
	verificationAdditionalInfoStatus: AdditionalInfoStatus;
	verificationIdentityVerificationStatus: IdentityVerificationStatus;
	verificationRiskProfile: RiskProfileStatus;
	verificationBankVerificationStatus: BankVerificationStatus;
};

export enum AdditionalInfoStatus {
	NotVerified = "NotVerified",
	Verified = "Verified",
	NeedsInvestigation = "NeedsInvestigation"
}

export enum IdentityVerificationStatus {
	NotVerified = "NotVerified",
	Verified = "Verified",
	Processing = "Processing"
}

export enum RiskProfileStatus {
	NotVerified = "NotVerified",
	NoRisk = "NoRisk",
	Risk = "Risk",
	Unacceptable = "Unacceptable"
}

export enum BankVerificationStatus {
	NotVerified = "NotVerified",
	Verified = "Verified",
	InProgress = "InProgress"
}

export type GetUserListQuery = {
	offset: number;
	limit: number;
};

export type UserAdminDetailDto = {
	userID: number;
	login: string;
	email: string;
	status: UserStatus;
	firstName?: string | null;
	lastName?: string | null;
	verificationAdditionalInfoStatus: AdditionalInfoStatus;
	verificationRiskProfile: RiskProfileStatus;
	verificationSourceOfIncome: SourceOfIncome;
	verificationHasMoneyFromCriminalActivityOrIsFinancingTerrorism: boolean;
	verificationHasConnectionToSanctionedCountry: boolean;
	verificationIsPoliticallyExposedPerson: boolean;
	verificationIdentityVerificationStatus: IdentityVerificationStatus;
	verificationBankVerificationStatus: BankVerificationStatus;
	verificationVerificationJson?: string | null;
	verificationExternalID?: string | null;
	verificationBirthnumberSha256?: string | null;
	verificationDailyBuyLimit: number;
	verificationMonthlyBuyLimit: number;
	hasIdentificationsUploaded: boolean;
};

export enum UserStatus {
	Unknown = "Unknown",
	InvitedWaitingForConfirmation = "InvitedWaitingForConfirmation",
	Active = "Active",
	WaitingForEmailConfirmation = "WaitingForEmailConfirmation",
	Deactivated = "Deactivated",
	BlockedFinances = "BlockedFinances"
}

export enum SourceOfIncome {
	Unknown = "Unknown",
	AlimonyOrWelfareOrPension = "AlimonyOrWelfareOrPension",
	WageOrSalary = "WageOrSalary",
	BusinessIncome = "BusinessIncome",
	CapitalGains = "CapitalGains"
}

export type SaveUserAdminDetailCommandResult = {
	status: SaveUserAdminDetailCommandResultStatus;
};

export enum SaveUserAdminDetailCommandResultStatus {
	Success = "Success"
}

export type DeactivateUserCommandResult = {
	status: DeactivateUserCommandResultStatus;
};

export enum DeactivateUserCommandResultStatus {
	Success = "Success"
}

export type UserVerificationDetailDto = {
	additionalInfoStatus: AdditionalInfoStatus;
	riskProfile: RiskProfileStatus;
	isPoliticallyExposedPerson: boolean;
	identityVerificationStatus: IdentityVerificationStatus;
	bankVerificationStatus: BankVerificationStatus;
	verificationLevel: number;
};

export type GetUserLimitsQueryResult = {
	remainingDailyLimit: number;
	remainingMonthlyLimit: number;
	currencyCode: string;
	verificationLevel: number;
	dailyBuyLimit: number;
	monthlyBuyLimit: number;
};

export type ProcessBankIDVerificationCommandResult = {
	status: ProcessBankIDVerificationCommandResultStatus;
	signInResult?: SignInResult | null;
};

export enum ProcessBankIDVerificationCommandResultStatus {
	BankIDUserInfoError = "BankIDUserInfoError",
	Success = "Success",
	Fail = "Fail",
	VerificationAlreadyExists = "VerificationAlreadyExists"
}

export type BankIDVerificationRequest = {
	token: string;
};

export type SetAdditionalInfoCommandResult = {
	verificationDetail: UserVerificationDetailDto;
};

export type SetAdditionalInfoRequest = {
	hasMoneyFromCriminalActivityOrIsFinancingTerrorism: boolean;
	hasConnectionToSanctionedCountry: boolean;
	isPoliticallyExposedPerson: boolean;
	sourceOfIncome: SourceOfIncome;
};

export type UploadIdentificationCommandResult = {
	status: UploadIdentificationCommandResultStatus;
};

export enum UploadIdentificationCommandResultStatus {
	Success = "Success",
	MissingFiles = "MissingFiles"
}

export type SetBankIdentificationInProgressCommandResult = {
	status: SetBankIdentificationInProgressCommandResultStatus;
	verificationDetail: UserVerificationDetailDto;
};

export enum SetBankIdentificationInProgressCommandResultStatus {
	Success = "Success"
}

export type ValidateVoucherCommandResult = {
	status: ValidateVoucherCommandResultStatus;
	code?: string | null;
};

export enum ValidateVoucherCommandResultStatus {
	NotFound = "NotFound",
	Valid = "Valid"
}

export type ValidateVoucherCommand = {
	code?: string | null;
};

export type ApplyVoucherCommandResult = {
	status: ApplyVoucherCommandResultStatus;
	code?: string | null;
	voucherFiatAmount?: number | null;
	login?: string | null;
	voucherFiatCurrencyID?: number | null;
};

export enum ApplyVoucherCommandResultStatus {
	NotFound = "NotFound",
	Applied = "Applied",
	UserAccountNotFound = "UserAccountNotFound",
	UserNotFound = "UserNotFound",
	UserIsWaitingForSignIn = "UserIsWaitingForSignIn",
	NotAllowed = "NotAllowed"
}

export type ApplyVoucherRequest = {
	code?: string | null;
	login?: string | null;
};

const API_URL = import.meta.env.VITE_API_URL;

export const postAuthSignIn = (requestContract: SignInCommand, headers = new Headers()): 
	Promise<FetchResponse<SignInResult>> => 
	apiPost(`${API_URL}/api/Auth/sign-in`, requestContract, headers);

export const postAuthSignUp = (requestContract: SignUpCommand, headers = new Headers()): 
	Promise<FetchResponse<SignUpCommandResult>> => 
	apiPost(`${API_URL}/api/Auth/sign-up`, requestContract, headers);

export const postAuthSignUpPasswordless = (requestContract: PasswordlessSignUpCommand, headers = new Headers()): 
	Promise<FetchResponse<PasswordlessSignUpCommandResult>> => 
	apiPost(`${API_URL}/api/Auth/sign-up-passwordless`, requestContract, headers);

export const postAuthVerifyEmail = (requestContract: EmailVerificationCommand, headers = new Headers()): 
	Promise<FetchResponse<EmailVerificationCommandResult>> => 
	apiPost(`${API_URL}/api/Auth/verify-email`, requestContract, headers);

export const postAuthResendVerificationEmail = (requestContract: ResendVerificationEmailCommand, headers = new Headers()): 
	Promise<FetchResponse<ResendVerificationEmailCommandResult>> => 
	apiPost(`${API_URL}/api/Auth/resend-verification-email`, requestContract, headers);

export const postAuthSso = (requestContract: SsoSignInRequest, headers = new Headers()): 
	Promise<FetchResponse<SignInResult>> => 
	apiPost(`${API_URL}/api/Auth/sso`, requestContract, headers);

export const getAuthSsoGoogleCallback = (code?: string | undefined | null, scope?: string | undefined | null, authuser?: string | undefined | null, hd?: string | undefined | null, prompt?: string | undefined | null, headers = new Headers()): 
	Promise<FetchResponse<any>> => {
	const queryParams = {
		"code": code		,
"scope": scope		,
"authuser": authuser		,
"hd": hd		,
"prompt": prompt
	}
	return apiGet(`${API_URL}/api/Auth/sso/google-callback`, headers, queryParams);
}

export const getAuthUserInfo = (headers = new Headers()): 
	Promise<FetchResponse<UserInfoQueryResult>> => {
	return apiGet(`${API_URL}/api/Auth/user-info`, headers, {});
}

export const postAuthSendPhoneVerificationToken = (requestContract: SendPhoneVerificationTokenCommand, headers = new Headers()): 
	Promise<FetchResponse<SendPhoneVerificationTokenCommandResult>> => 
	apiPost(`${API_URL}/api/Auth/send-phone-verification-token`, requestContract, headers);

export const postAuthVerifyPhone = (requestContract: VerifyPhoneCommand, headers = new Headers()): 
	Promise<FetchResponse<VerifyPhoneCommandResult>> => 
	apiPost(`${API_URL}/api/Auth/verify-phone`, requestContract, headers);

export const postAuthResetPassword = (requestContract: ResetPasswordCommand, headers = new Headers()): 
	Promise<FetchResponse<ResetPasswordCommandResult>> => 
	apiPost(`${API_URL}/api/Auth/reset-password`, requestContract, headers);

export const postAuthSetPassword = (requestContract: SetPasswordCommand, headers = new Headers()): 
	Promise<FetchResponse<SetPasswordCommandResult>> => 
	apiPost(`${API_URL}/api/Auth/set-password`, requestContract, headers);

export const getBalance = (headers = new Headers()): 
	Promise<FetchResponse<GetBalanceQueryResult>> => {
	return apiGet(`${API_URL}/api/Balance`, headers, {});
}

export const postBalanceWithdraw = (requestContract: WithdrawalRequest, headers = new Headers()): 
	Promise<FetchResponse<AddWithdrawalToQueueCommandResult>> => 
	apiPost(`${API_URL}/api/Balance/withdraw`, requestContract, headers);

export const postBalanceSell = (requestContract: SellRequest, headers = new Headers()): 
	Promise<FetchResponse<SellCommandResult>> => 
	apiPost(`${API_URL}/api/Balance/sell`, requestContract, headers);

export const getCacheRefresh = (headers = new Headers()): 
	Promise<FetchResponse<any>> => {
	return apiGet(`${API_URL}/api/Cache/refresh`, headers, {});
}

export const getCodeList = (headers = new Headers()): 
	Promise<FetchResponse<GetCodeListCollectionQueryResult>> => {
	return apiGet(`${API_URL}/api/CodeList`, headers, {});
}

export const postCodeListCodeLists = (headers = new Headers()): 
	Promise<FetchResponse<GetCodeListCollectionQueryResult>> => 
	apiPost(`${API_URL}/api/CodeList/code-lists`, {}, headers);

export const getCoinbackOffers = (offset?: number, limit?: number, categoryCode?: string | undefined | null, headers = new Headers()): 
	Promise<FetchResponse<EntityListOfOfferListItemDto>> => {
	const queryParams = {
		"offset": offset		,
"limit": limit		,
"categoryCode": categoryCode
	}
	return apiGet(`${API_URL}/api/Coinback/offers`, headers, queryParams);
}

export const getCoinbackCategories = (headers = new Headers()): 
	Promise<FetchResponse<EntityListOfCategoryDto>> => {
	return apiGet(`${API_URL}/api/Coinback/categories`, headers, {});
}

export const getCoinbackOffersForExtension = (headers = new Headers()): 
	Promise<FetchResponse<EntityListOfOfferBrowserExtensionDto>> => {
	return apiGet(`${API_URL}/api/Coinback/offers-for-extension`, headers, {});
}

export const getCoinbackOffer = (webUrl?: string | undefined | null, headers = new Headers()): 
	Promise<FetchResponse<OfferDto>> => {
	const queryParams = {
		"webUrl": webUrl
	}
	return apiGet(`${API_URL}/api/Coinback/offer`, headers, queryParams);
}

export const getHashSha256 = (value?: string | undefined | null, headers = new Headers()): 
	Promise<FetchResponse<string>> => {
	const queryParams = {
		"value": value
	}
	return apiGet(`${API_URL}/api/Hash/sha-256`, headers, queryParams);
}

export const postInvoiceInvoiceList = (headers = new Headers()): 
	Promise<FetchResponse<EntityListOfInvoiceListItemDto>> => 
	apiPost(`${API_URL}/api/Invoice/invoice-list`, {}, headers);

export const postInvoiceCreateInvoice = (requestContract: CreateInvoiceRequest, headers = new Headers()): 
	Promise<FetchResponse<CreateInvoiceCommandResult>> => 
	apiPost(`${API_URL}/api/Invoice/create-invoice`, requestContract, headers);

export const postInvoiceGetInvoiceRateChange = (requestContract: GetInvoiceRateChangeDetailQuery, headers = new Headers()): 
	Promise<FetchResponse<GetInvoiceRateChangeDetailQueryResult>> => 
	apiPost(`${API_URL}/api/Invoice/get-invoice-rate-change`, requestContract, headers);

export const postInvoiceProcessInvoiceRateChange = (requestContract: ProcessInvoiceRateChangeRequest, headers = new Headers()): 
	Promise<FetchResponse<ProcessInvoiceRateChangeCommandResult>> => 
	apiPost(`${API_URL}/api/Invoice/process-invoice-rate-change`, requestContract, headers);

export const postInvoiceDownloadEmployeeGuide = (headers = new Headers()): 
	Promise<FetchResponse<any>> => 
	apiPost(`${API_URL}/api/Invoice/download-employee-guide`, {}, headers);

export const postInvoiceGetInvoiceSettings = (headers = new Headers()): 
	Promise<FetchResponse<InvoiceSettingsDto>> => 
	apiPost(`${API_URL}/api/Invoice/get-invoice-settings`, {}, headers);

export const postInvoiceSaveInvoiceSettings = (requestContract: InvoiceSettingsDto, headers = new Headers()): 
	Promise<FetchResponse<SaveInvoiceSettingsCommandResult>> => 
	apiPost(`${API_URL}/api/Invoice/save-invoice-settings`, requestContract, headers);

export const postInvoiceGetUserFeeRate = (headers = new Headers()): 
	Promise<FetchResponse<GetUserFeeRateQueryResult>> => 
	apiPost(`${API_URL}/api/Invoice/get-user-fee-rate`, {}, headers);

export const postLeadList = (headers = new Headers()): 
	Promise<FetchResponse<EntityListOfLeadDto>> => 
	apiPost(`${API_URL}/api/Lead/list`, {}, headers);

export const postLeadInvite = (requestContract: InviteLeadRequest, headers = new Headers()): 
	Promise<FetchResponse<LeadInvitationCommandResult>> => 
	apiPost(`${API_URL}/api/Lead/invite`, requestContract, headers);

export const postLogError = (requestContract: ErrorWebLogCommand, headers = new Headers()): 
	Promise<FetchResponse<any>> => 
	apiPost(`${API_URL}/api/Log/Error`, requestContract, headers);

export const postNotificationGetNotificationSettings = (headers = new Headers()): 
	Promise<FetchResponse<UserNotificationSettingsDto>> => 
	apiPost(`${API_URL}/api/Notification/get-notification-settings`, {}, headers);

export const postNotificationSaveNotificationSettings = (requestContract: UserNotificationSettingsDto, headers = new Headers()): 
	Promise<FetchResponse<SaveUserNotificationSettingsCommandResult>> => 
	apiPost(`${API_URL}/api/Notification/save-notification-settings`, requestContract, headers);

export const postRateGetRate = (requestContract: GetRateQuery, headers = new Headers()): 
	Promise<FetchResponse<GetRateQueryResult>> => 
	apiPost(`${API_URL}/api/Rate/get-rate`, requestContract, headers);

export const getRateHistory = (askCurrencyCode?: string | undefined | null, bidCurrencyCode?: string | undefined | null, startDate?: string, headers = new Headers()): 
	Promise<FetchResponse<GetRateHistoryQueryResult>> => {
	const queryParams = {
		"askCurrencyCode": askCurrencyCode		,
"bidCurrencyCode": bidCurrencyCode		,
"startDate": startDate
	}
	return apiGet(`${API_URL}/api/Rate/history`, headers, queryParams);
}

export const postSystemGetReport = (headers = new Headers()): 
	Promise<FetchResponse<GetSystemReportQueryResult>> => 
	apiPost(`${API_URL}/api/System/get-report`, {}, headers);

export const postSystemWithdraw = (requestContract: SystemWithdrawalRequest, headers = new Headers()): 
	Promise<FetchResponse<ProcessSystemWithdrawalResult>> => 
	apiPost(`${API_URL}/api/System/withdraw`, requestContract, headers);

export const postSystemGenerateAddress = (headers = new Headers()): 
	Promise<FetchResponse<GenerateSystemAddressCommandResult>> => 
	apiPost(`${API_URL}/api/System/generate-address`, {}, headers);

export const postSystemGetSystemSetting = (requestContract: GetSystemSettingQuery, headers = new Headers()): 
	Promise<FetchResponse<SystemSettingDto>> => 
	apiPost(`${API_URL}/api/System/get-system-setting`, requestContract, headers);

export const postSystemSaveSystemSetting = (requestContract: SaveSystemSettingCommand, headers = new Headers()): 
	Promise<FetchResponse<SaveSystemSettingCommandResult>> => 
	apiPost(`${API_URL}/api/System/save-system-setting`, requestContract, headers);

export const postTransactionTransactionList = (requestContract: GetTransactionListRequest, headers = new Headers()): 
	Promise<FetchResponse<EntityListOfTransactionListItemDto>> => 
	apiPost(`${API_URL}/api/Transaction/transaction-list`, requestContract, headers);

export const getTransactionDetailExternalID = (externalID: string, headers = new Headers()): 
	Promise<FetchResponse<GetTransactionDetailQueryResult>> => {
	return apiGet(`${API_URL}/api/Transaction/detail/${externalID}`, headers, {});
}

export const getTransactionCsvExport = (headers = new Headers()): 
	Promise<FetchResponse<any>> => {
	return apiGet(`${API_URL}/api/Transaction/csv-export`, headers, {});
}

export const postUserBankAccountGetDetail = (requestContract: UserBankAccountDetailRequest, headers = new Headers()): 
	Promise<FetchResponse<UserBankAccountDetailDto>> => 
	apiPost(`${API_URL}/api/UserBankAccount/get-detail`, requestContract, headers);

export const postUserBankAccountBankAddressList = (headers = new Headers()): 
	Promise<FetchResponse<EntityListOfUserBankAccountListDto>> => 
	apiPost(`${API_URL}/api/UserBankAccount/bank-address-list`, {}, headers);

export const postUserBankAccountCreate = (headers = new Headers()): 
	Promise<FetchResponse<CreateUserBankAccountCommandResult>> => 
	apiPost(`${API_URL}/api/UserBankAccount/create`, {}, headers);

export const postUserList = (requestContract: GetUserListQuery, headers = new Headers()): 
	Promise<FetchResponse<EntityListOfUserDto>> => 
	apiPost(`${API_URL}/api/User/list`, requestContract, headers);

export const getUserAdminDetailUserID = (userID: number, headers = new Headers()): 
	Promise<FetchResponse<UserAdminDetailDto>> => {
	return apiGet(`${API_URL}/api/User/admin-detail/${userID}`, headers, {});
}

export const postUserAdminDetail = (requestContract: UserAdminDetailDto, headers = new Headers()): 
	Promise<FetchResponse<SaveUserAdminDetailCommandResult>> => 
	apiPost(`${API_URL}/api/User/admin-detail`, requestContract, headers);

export const getUserUserIdentificationsZipUserID = (userID: number, headers = new Headers()): 
	Promise<FetchResponse<any>> => {
	return apiGet(`${API_URL}/api/User/user-identifications-zip/${userID}`, headers, {});
}

export const putUserDeactivate = (headers = new Headers()): 
	Promise<FetchResponse<DeactivateUserCommandResult>> => 
	apiPut(`${API_URL}/api/User/deactivate`, {}, headers);

export const postVerificationGetUserVerificationDetail = (headers = new Headers()): 
	Promise<FetchResponse<UserVerificationDetailDto>> => 
	apiPost(`${API_URL}/api/Verification/get-user-verification-detail`, {}, headers);

export const postVerificationGetUserLimits = (headers = new Headers()): 
	Promise<FetchResponse<GetUserLimitsQueryResult>> => 
	apiPost(`${API_URL}/api/Verification/get-user-limits`, {}, headers);

export const postVerificationVerifyBankId = (requestContract: BankIDVerificationRequest, headers = new Headers()): 
	Promise<FetchResponse<ProcessBankIDVerificationCommandResult>> => 
	apiPost(`${API_URL}/api/Verification/verify-bank-id`, requestContract, headers);

export const postVerificationVerifyAdditionalInfo = (requestContract: SetAdditionalInfoRequest, headers = new Headers()): 
	Promise<FetchResponse<SetAdditionalInfoCommandResult>> => 
	apiPost(`${API_URL}/api/Verification/verify-additional-info`, requestContract, headers);

export const postVerificationUploadIdentification = (headers = new Headers()): 
	Promise<FetchResponse<UploadIdentificationCommandResult>> => 
	apiPost(`${API_URL}/api/Verification/upload-identification`, {}, headers);

export const postVerificationSetBankIdentificationInProgress = (headers = new Headers()): 
	Promise<FetchResponse<SetBankIdentificationInProgressCommandResult>> => 
	apiPost(`${API_URL}/api/Verification/set-bank-identification-in-progress`, {}, headers);

export const postVoucherValidate = (requestContract: ValidateVoucherCommand, headers = new Headers()): 
	Promise<FetchResponse<ValidateVoucherCommandResult>> => 
	apiPost(`${API_URL}/api/Voucher/validate`, requestContract, headers);

export const postVoucherApply = (requestContract: ApplyVoucherRequest, headers = new Headers()): 
	Promise<FetchResponse<ApplyVoucherCommandResult>> => 
	apiPost(`${API_URL}/api/Voucher/apply`, requestContract, headers);

export const API = { 
	postAuthSignIn,
	postAuthSignUp,
	postAuthSignUpPasswordless,
	postAuthVerifyEmail,
	postAuthResendVerificationEmail,
	postAuthSso,
	getAuthSsoGoogleCallback,
	getAuthUserInfo,
	postAuthSendPhoneVerificationToken,
	postAuthVerifyPhone,
	postAuthResetPassword,
	postAuthSetPassword,
	getBalance,
	postBalanceWithdraw,
	postBalanceSell,
	getCacheRefresh,
	getCodeList,
	postCodeListCodeLists,
	getCoinbackOffers,
	getCoinbackCategories,
	getCoinbackOffersForExtension,
	getCoinbackOffer,
	getHashSha256,
	postInvoiceInvoiceList,
	postInvoiceCreateInvoice,
	postInvoiceGetInvoiceRateChange,
	postInvoiceProcessInvoiceRateChange,
	postInvoiceDownloadEmployeeGuide,
	postInvoiceGetInvoiceSettings,
	postInvoiceSaveInvoiceSettings,
	postInvoiceGetUserFeeRate,
	postLeadList,
	postLeadInvite,
	postLogError,
	postNotificationGetNotificationSettings,
	postNotificationSaveNotificationSettings,
	postRateGetRate,
	getRateHistory,
	postSystemGetReport,
	postSystemWithdraw,
	postSystemGenerateAddress,
	postSystemGetSystemSetting,
	postSystemSaveSystemSetting,
	postTransactionTransactionList,
	getTransactionDetailExternalID,
	getTransactionCsvExport,
	postUserBankAccountGetDetail,
	postUserBankAccountBankAddressList,
	postUserBankAccountCreate,
	postUserList,
	getUserAdminDetailUserID,
	postUserAdminDetail,
	getUserUserIdentificationsZipUserID,
	putUserDeactivate,
	postVerificationGetUserVerificationDetail,
	postVerificationGetUserLimits,
	postVerificationVerifyBankId,
	postVerificationVerifyAdditionalInfo,
	postVerificationUploadIdentification,
	postVerificationSetBankIdentificationInProgress,
	postVoucherValidate,
	postVoucherApply
}

