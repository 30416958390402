import {
  SaveUserAdminDetailCommandResult,
  postUserAdminDetail,
  UserAdminDetailDto,
} from "Api/Api";
import { createAsyncAction } from "typesafe-actions";
import { put, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { call } from "typed-redux-saga";
import { getUserAdminDetailAsync } from "State/Users/Detail/GetUserAdminDetailState";
import { toast } from "react-toastify";

export const saveUserAdminDetailAsync = createAsyncAction(
  "@user/SAVE_REQUEST",
  "@user/SAVE_SUCCESS",
  "@user/SAVE_FAILURE",
)<UserAdminDetailDto, SaveUserAdminDetailCommandResult, Error>();

function* saveUserAdminDetail(
  action: ReturnType<typeof saveUserAdminDetailAsync.request>,
): Generator {
  try {
    const response = yield* call(postUserAdminDetail, action.payload);
    if (response.status === 200) {
      yield put(saveUserAdminDetailAsync.success(response.json));
      toast.success("Uložení proběhlo úspěšně");
      yield put(getUserAdminDetailAsync.success(null));
      yield put(getUserAdminDetailAsync.request(action.payload.userID));
    } else {
      yield put(
        saveUserAdminDetailAsync.failure(
          new Error(`Error in action ${action.type}`, {
            cause: response.status,
          }),
        ),
      );
    }
  } catch (err) {
    yield put(saveUserAdminDetailAsync.failure(err as Error));
  }
}
export function* saveUserAdminDetailSaga() {
  yield takeLatest(
    getType(saveUserAdminDetailAsync.request),
    saveUserAdminDetail,
  );
}
