import { Typography } from "@mui/material";
import { ApplicationLink } from "Components/Layout/Menu/Menu";
import {
  MoneyBagBitcoinIcon,
  MoneyBoxIcon,
  BitcoinAcceptedIcon,
  GiftCardIcon,
  CoinsIcon,
  GearsIcon,
  GmailLogoIcon,
  AskQuestionIcon,
  LogoutRoundedIcon,
  SafeIcon,
  GraphReportIcon,
  SystemIcon,
  SelectUsersIcon,
} from "Components/Shared/Icons";
import { AccessRightCodes } from "Models/AccessRightCodes";
import { useDispatch } from "react-redux";
import { resetUser } from "State/Auth/AuthReducer";
import { getAppUrl } from "Utils/UrlUtils";

export function getAllLinks(
  appUrl: ReturnType<typeof getAppUrl>,
  dispatch: ReturnType<typeof useDispatch>,
): ApplicationLink[] {
  return [
    {
      text: <Typography>{"Přehled"}</Typography>,
      to: appUrl("dashboard"),
      accessRightCode: AccessRightCodes.Dashboard,
      icon: <MoneyBagBitcoinIcon />,
    },
    {
      text: <Typography>Koupit bitcoin</Typography>,
      to: appUrl("quick-exchange"),
      accessRightCode: AccessRightCodes.Dashboard,
      icon: <MoneyBoxIcon />,
    },
    {
      text: <Typography>{"Jak nakoupit?"}</Typography>,
      to: appUrl("faq"),
      accessRightCode: AccessRightCodes.Dashboard,
      icon: <BitcoinAcceptedIcon />,
    },
    {
      text: <Typography>{"Dárková karta"}</Typography>,
      to: appUrl("redeem"),
      accessRightCode: AccessRightCodes.Dashboard,
      icon: <GiftCardIcon />,
    },
    {
      text: <Typography>{"Coinback"}</Typography>,
      to: appUrl("coinback"),
      accessRightCode: AccessRightCodes.Dashboard,
      icon: <CoinsIcon />,
    },
    {
      text: <Typography>{"Nastavení"}</Typography>,
      to: appUrl("settings"),
      accessRightCode: AccessRightCodes.Dashboard,
      icon: <GearsIcon />,
    },
    {
      text: <Typography>{"Napište nám"}</Typography>,
      to: appUrl("contact"),
      accessRightCode: AccessRightCodes.Dashboard,
      icon: <GmailLogoIcon />,
    },
    {
      text: <Typography>{"Nápověda"}</Typography>,
      to: "https://www.coinwage.io/casto-kladene-dotazy",
      accessRightCode: AccessRightCodes.Dashboard,
      isExternalLink: true,
      icon: <AskQuestionIcon />,
    },

    {
      text: <Typography>{"Sys. report"}</Typography>,
      to: appUrl("system"),
      accessRightCode: AccessRightCodes.SystemReport,
      icon: <GraphReportIcon />,
    },
    {
      text: <Typography>{"Cold wallet"}</Typography>,
      to: appUrl("cold-wallet"),
      accessRightCode: AccessRightCodes.ColdWallet,
      icon: <SafeIcon />,
    },
    {
      text: <Typography>{"Nastavení sys."}</Typography>,
      to: appUrl("system-control"),
      accessRightCode: AccessRightCodes.SystemControl,
      icon: <SystemIcon />,
    },
    {
      text: <Typography>{"Uživatelé"}</Typography>,
      to: appUrl("user-list"),
      accessRightCode: AccessRightCodes.Users,
      icon: <SelectUsersIcon />,
    },
    {
      text: <Typography>Odhlásit</Typography>,
      to: appUrl("sign-in"),
      accessRightCode: AccessRightCodes.Dashboard,
      icon: <LogoutRoundedIcon />,
      onClick: () => {
        dispatch(resetUser({}));
      },
    },
  ];
}
