import { LogoIcon } from "Components/Shared/Icons";
import styled from "styled-components";

type Props = {
  size?: number;
};

const StyledWrapper = styled.div<{ width: number }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: ${props => props.width}px;
  z-index: 0;

  svg {
    width: auto;
    height: auto;
    z-index: 1;
  }

  .logo-shadow {
    position: absolute;
    left: 15%;
    right: 5%;
    bottom: 0;
    top: 56%;
    filter: blur(4.77px);
    background-color: #000;
    opacity: 0.1;
    transform: rotate3d(0, 10, 32, -14deg);
    z-index: -1;
  }
`;

export const Logo: React.FunctionComponent<React.PropsWithChildren<Props>> = (
  props: Props,
) => {
  return (
    <StyledWrapper width={props.size ?? 52}>
      <LogoIcon width={props.size ?? 52} height={props.size ?? 52}></LogoIcon>
      <div className={"logo-shadow"}></div>
    </StyledWrapper>
  );
};
