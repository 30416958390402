import { Typography } from "@mui/material";
import styled from "styled-components";

type Props = { amount: number; prefix?: string };

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const Currency = styled(Typography)`
  display: flex;
  flex-direction: column;
  justify-content: end;
  margin-bottom: 5px;
`;

export const BitcoinBalance: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = props => {
  const { amount, prefix } = props;
  const formattedValue =
    amount === 0
      ? "0"
      : `${prefix ?? ""}${new Intl.NumberFormat("cs-CZ", {
          maximumFractionDigits: 8,
          minimumFractionDigits: 8,
        }).format(amount ?? 0)}`;
  return (
    <>
      <Wrapper>
        <Typography variant="h4" fontWeight={400} fontSize={38}>
          {formattedValue}
        </Typography>
        <Currency fontSize={16}>BTC</Currency>
      </Wrapper>
    </>
  );
};
